import React, { useContext } from 'react'
import {
  Box,
  Theme,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import muiTheme from '@config/theme'
import useInViewAnim from '@components/system/hooks/useInViewAnim'
import Section from '@components/core/container/section'
import Icon from '@components/core/ui/icon'

import ThemeContext from '@providers/themeProvider'

import ScrollIndicator from '@components/core/ui/scrollIndicator'

export type StageProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    caption?: string
    subline?: string
    images?: (LAW.Props.Image | LAW.Props.Svg)[]
    background?: LAW.Props.Video | LAW.Props.Image
    image?: LAW.Props.Video | LAW.Props.Image
    imageMobile?: LAW.Props.Video | LAW.Props.Image
    fullWidth?: boolean
    theme?: LAW.Contentful.Theme
    pageThemeOverride?: boolean
    linkTo?: LAW.Props.LinkTo
    teaserHeadline?: string
    teaserLinkTo?: LAW.Props.LinkTo
    icon?: string
    scrollIndicator?: boolean
  }

export default function Stage({
  headline,
  caption,
  subline,
  image,
  imageMobile,
  theme,
  pageThemeOverride,
  fullWidth,
  scrollIndicator,
  icon,
}: StageProps): React.ReactElement {
  const [textInViewRef, textInViewSX] = useInViewAnim('bottom-to-top')

  const [iconInViewRef, iconInViewSX] = useInViewAnim('bottom-to-top', {
    delay: 0.7,
  })

  const { theme: pageTheme } = useContext(ThemeContext)

  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('md')
  )

  const halfWidthSxProps = fullWidth
    ? { maxWidth: '700px' }
    : {
        width: '50%',
        paddingRight: '64px',
        paddingLeft: '132px',
      }

  return (
    <>
      <Section
        roundedBorders={fullWidth}
        theme={theme}
        background={image}
        backgroundMobile={imageMobile}
        imageEagerLoading
        maxWidth="xl"
        paddingTop
        paddingBottom={!!image}
        full={fullWidth}
        sx={(muiTheme: Theme) => ({
          marginTop: 0,
          minHeight: '85vh',
          '& .gatsby-image-wrapper': {
            height: '100%',
            willChange: 'transform', // safari fix
            '& img': {
              overflow: 'hidden',
              borderTopLeftRadius: !fullWidth ? '0px' : '32px',
              borderTopRightRadius: !fullWidth ? '0px' : '32px',
              willChange: 'transform', // safari fix
              [muiTheme.breakpoints.up('md')]: {
                borderTopLeftRadius: !fullWidth ? '16px' : '40px',
                borderTopRightRadius: !fullWidth ? '0px' : '40px',
                borderBottomLeftRadius: !fullWidth ? '16px' : '0px',
              },
            },
          },
          backgroundColor: 'transparent',
          [muiTheme.breakpoints.down('md')]: {
            height: '100vh',
            '& > div:nth-of-type(2)': {
              position: !fullWidth ? 'unset' : 'absolute',
            },
            borderTopLeftRadius: !fullWidth ? '0px' : '32px',
            borderTopRightRadius: !fullWidth ? '0px' : '32px',
            '& > .MuiContainer-root': {
              borderTopLeftRadius: '32px',
              borderTopRightRadius: '32px',
              backgroundColor: !fullWidth ? 'white' : 'none',
              pt: 0,
              pb: fullWidth ? 20 : 12,
            },
          },
          [muiTheme.breakpoints.up('md')]: {
            '& > .MuiContainer-root': {
              p: 0,
            },
            [muiTheme.breakpoints.up('md')]: {
              overflow: 'hidden',
              '& > .MuiContainer-root': {
                minHeight: '80vh',
                display: 'flex',
                alignItems: 'center',
              },
            },
          },
        })}
      >
        <Container
          maxWidth="xl"
          sx={{
            px: 0,
            pt: { xs: fullWidth ? 20 : 12, md: 0 },
            m: 0,
            '&.MuiContainer-root': {
              [muiTheme.breakpoints.up('md')]: {
                px: fullWidth ? 34 : 0,
              },
            },
          }}
        >
          <Box
            ref={textInViewRef}
            sx={(muiTheme: Theme) => ({
              ...textInViewSX,
              [muiTheme.breakpoints.up('md')]: {
                ...halfWidthSxProps,
              },
            })}
          >
            {caption && (
              <Typography
                variant="caption"
                sx={(muiTheme: Theme) => ({
                  color:
                    pageTheme === 'sustainability'
                      ? muiTheme.palette.sustainability.main
                      : 'primary.main',
                  display: 'inline-block',
                  textTransform: 'capitalize',
                  mb: 2,
                  [muiTheme.breakpoints.up('md')]: {
                    mb: 4,
                  },
                })}
              >
                {caption}
              </Typography>
            )}
            <Typography variant="h1">{headline}</Typography>

            {subline && (
              <Typography
                component="p"
                variant="body1"
                sx={{
                  mt: { xs: 4, md: 8 },
                }}
              >
                {subline}
              </Typography>
            )}

            {icon && (
              <IconButton>
                <Icon name={icon.name} sx={icon.sx} />
              </IconButton>
            )}
          </Box>

          {!!scrollIndicator && !isMobile && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '0px',
              }}
            >
              <Box
                ref={iconInViewRef}
                sx={{
                  ...iconInViewSX,
                  padding: 0,
                  ml: fullWidth ? 0 : 33,
                  mt: 3,
                  mb: 30,
                }}
              >
                <ScrollIndicator pageThemeOverride={pageThemeOverride} />
              </Box>
            </Box>
          )}
        </Container>
      </Section>
    </>
  )
}
