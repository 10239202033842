import React, { useContext } from 'react'
import { Box, Typography, Theme, useTheme } from '@mui/material'

import useInViewAnim from '@components/system/hooks/useInViewAnim'

import renderRichText from '@utils/richtext'
import Button from '@components/core/ui/button'

import ThemeContext from '@providers/themeProvider'

export interface ITextBlock {
  theme?: string
  caption?: string
  headline: string
  copy?: LAW.Contentful.BasicRichTextType
  linkTo?: LAW.Props.LinkTo
  callToAction?: string
  noPadding?: boolean
  animationType?: 'bottom-to-top' | 'right-to-left' | 'left-to-right'
}

export default function TextBlock({
  theme = 'light',
  caption,
  headline,
  copy,
  linkTo,
  callToAction,
  noPadding = false,
  animationType = 'bottom-to-top',
}: ITextBlock): React.ReactElement {
  const [headlineInViewRef, headlineInViewSX] = useInViewAnim(animationType)
  const [textInViewRef, textInViewSX] = useInViewAnim(animationType, {
    delay: 0.2,
  })
  const [buttonInViewRef, buttonInViewSX] = useInViewAnim(animationType, {
    delay: 0.3,
  })
  const muiTheme = useTheme()
  const { theme: pageTheme } = useContext(ThemeContext)

  return (
    <Box
      sx={{
        mb: noPadding ? 0 : { xs: 12, md: 20 },
        maxWidth: 768,
      }}
    >
      <Box
        ref={headlineInViewRef}
        sx={[
          headlineInViewSX,
          {
            '&:not(:only-child)': {
              mb: { xs: 4, lg: 8 },
            },
          },
        ]}
      >
        {caption && (
          <Typography
            variant="caption"
            component="div"
            sx={(muiTheme: Theme) => ({
              mb: { xs: 2, md: 4 },
              color:
                theme !== 'light'
                  ? muiTheme.palette.text.inverted
                  : pageTheme === 'sustainability'
                  ? muiTheme.palette.sustainability.main
                  : undefined,
            })}
          >
            {caption}
          </Typography>
        )}

        <Typography variant="h2">{headline}</Typography>
      </Box>

      {copy && (
        <Typography
          ref={textInViewRef}
          variant="body2"
          sx={{
            ...textInViewSX,
            mb: { xs: 8, xl: 12 },
          }}
        >
          {renderRichText(copy, true)}
        </Typography>
      )}

      {linkTo && (
        <Box ref={buttonInViewRef} sx={buttonInViewSX}>
          <Button
            variant="primary"
            to={linkTo.url}
            sx={{
              background:
                theme !== 'light'
                  ? '#fff'
                  : pageTheme === 'sustainability'
                  ? muiTheme.palette.sustainability.main
                  : undefined,
              color:
                theme === 'red'
                  ? pageTheme === 'sustainability'
                    ? muiTheme.palette.sustainability.main
                    : muiTheme.palette.primary.main
                  : undefined,
              '&:hover': {
                background:
                  theme !== 'light'
                    ? '#fff'
                    : pageTheme === 'sustainability'
                    ? muiTheme.palette.sustainability.main
                    : undefined,
              },
            }}
          >
            {!!callToAction ? callToAction : linkTo.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}
