import React from 'react'
import { Box, styled, Typography } from '@mui/material'

import useInViewAnim from '@components/system/hooks/useInViewAnim'

import Icon, { IconNamesType } from '@components/core/ui/icon'
import Section from '@components/core/container/section'
import Link from '@components/core/ui/link'

export type TeaserSocialsProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    socialIcon: IconNamesType
    socialCopy: string
    linkTo?: LAW.Props.LinkTo
    callToAction?: string
    socialIcon2: IconNamesType
    socialCopy2: string
    callToAction2?: string
    linkTo2?: LAW.Props.LinkTo2
    socialIcon3: IconNamesType
    socialCopy3: string
    callToAction3?: string
    linkTo3?: LAW.Props.LinkTo2
    theme?: LAW.Contentful.Theme
  }

export default function TeaserSocials({
  socialIcon,
  socialCopy,
  socialIcon2,
  socialCopy2,
  socialIcon3,
  socialCopy3,
  linkTo,
  linkTo2,
  linkTo3,
  callToAction,
  callToAction2,
  callToAction3,
  theme,
}: TeaserSocialsProps): React.ReactElement {
  const [text1InViewRef, text1InViewSx] = useInViewAnim('bottom-to-top')
  const [text2InViewRef, text2InViewSx] = useInViewAnim('bottom-to-top')

  const [text3InViewRef, text3InViewSx] = useInViewAnim('bottom-to-top')
  const [text4InViewRef, text4InViewSx] = useInViewAnim('bottom-to-top')
  const [text5InViewRef, text5InViewSx] = useInViewAnim('bottom-to-top')
  const [text6InViewRef, text6InViewSx] = useInViewAnim('bottom-to-top')
  const SxLink = styled('a')({})

  return (
    <Section
      theme={theme}
      roundedBorders
      withShadow
      maxWidth="xl"
      sx={{
        pt: { xs: 16, md: 20 },
        pb: { xs: 25, md: 30 },
      }}
    >
      <Box
        ref={text1InViewRef}
        sx={{
          ...text1InViewSx,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: { xs: 'center', md: 'space-between' },
          pl: { md: 0, lg: 9, xl: 0, xxl: 9 },
        }}
      >
        <SxLink
          href={linkTo?.url}
          target="_blank"
          sx={{
            textDecoration: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mb: { xs: '32px', md: 0 },
            }}
          >
            <Icon
              name={socialIcon}
              color="inherit"
              fontSize="large"
              sx={{
                '& path': {
                  fill: '#fff',
                },
                '& use': {
                  fill: '#fff',
                },
              }}
            />
            <Typography
              variant="h3"
              sx={{
                color: 'text.inverted',

                ...(!!socialIcon && {
                  ml: { xs: '16px', md: '32px' },
                }),
              }}
            >
              {socialCopy}
            </Typography>
          </Box>
        </SxLink>
        {linkTo && (
          <Box
            ref={text2InViewRef}
            sx={{
              ...text2InViewSx,
              ml: { xs: '16px', md: 0 },
            }}
          >
            <Link
              withIcon
              iconColor="#fff"
              fontSize="large"
              fontWeight="bold"
              to={linkTo.url}
              isExternal
              sx={{
                color: 'text.inverted',
              }}
            >
              {!!callToAction ? callToAction : linkTo.label}
            </Link>
          </Box>
        )}
      </Box>
      {!!linkTo2 && (
        <Box
          ref={text3InViewRef}
          sx={{
            ...text3InViewSx,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
            marginTop: { xs: 12, md: 8 },
          }}
        >
          <SxLink
            href={linkTo2?.url}
            target="_blank"
            sx={{
              textDecoration: 'none',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: { xs: '32px', md: 0 },
              }}
            >
              <Icon
                name={socialIcon2}
                color="inherit"
                fontSize="large"
                sx={{
                  '& path': {
                    fill: '#fff',
                  },
                  '& use': {
                    fill: '#fff',
                  },
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  color: 'text.inverted',
                  ...(!!socialIcon2 && {
                    ml: { xs: '16px', md: '32px' },
                  }),
                }}
              >
                {socialCopy2}
              </Typography>
            </Box>
          </SxLink>
          {linkTo2 && (
            <Box
              ref={text4InViewRef}
              sx={{
                ...text4InViewSx,
                ml: { xs: '16px', md: 0 },
              }}
            >
              <Link
                withIcon
                iconColor="#fff"
                fontSize="large"
                fontWeight="bold"
                to={linkTo2.url}
                isExternal
                sx={{
                  color: 'text.inverted',
                }}
              >
                {!!callToAction2 ? callToAction2 : linkTo2.label}
              </Link>
            </Box>
          )}
        </Box>
      )}
      {!!linkTo3 && (
        <Box
          ref={text5InViewRef}
          sx={{
            ...text5InViewSx,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'space-between' },
            marginTop: { xs: 12, md: 8 },
          }}
        >
          <SxLink
            href={linkTo3?.url}
            target="_blank"
            sx={{
              textDecoration: 'none',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: { xs: '32px', md: 0 },
              }}
            >
              <Icon
                name={socialIcon3}
                color="inherit"
                fontSize="large"
                sx={{
                  '& path': {
                    fill: '#fff',
                  },
                  '& use': {
                    fill: '#fff',
                  },
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  color: 'text.inverted',
                  ...(!!socialIcon3 && {
                    ml: { xs: '16px', md: '32px' },
                  }),
                }}
              >
                {socialCopy3}
              </Typography>
            </Box>
          </SxLink>
          {linkTo3 && (
            <Box
              ref={text6InViewRef}
              sx={{
                ...text6InViewSx,
                ml: { xs: '16px', md: 0 },
              }}
            >
              <Link
                withIcon
                iconColor="#fff"
                fontSize="large"
                fontWeight="bold"
                to={linkTo3.url}
                isExternal
                sx={{
                  color: 'text.inverted',
                }}
              >
                {!!callToAction3 ? callToAction3 : linkTo3.label}
              </Link>
            </Box>
          )}
        </Box>
      )}
    </Section>
  )
}
