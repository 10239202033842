import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import Stage, { StageProps } from '@components/modules/stage'
import TeaserSection, {
  TeaserSectionProps,
} from '@components/modules/teaserSection'
import TeaserRelated, {
  TeaserRelatedProps,
} from '@components/modules/teaserRelated'
import TeaserCarousel, {
  TeaserCarouselProps,
} from '@components/modules/teaserCarousel'
import Accordion, { AccordionProps } from '@components/modules/accordion'
import { getAssetProp, getRichTextProp } from '@utils/props'

import TeaserSocials, {
  TeaserSocialsProps,
} from '@components/modules/teaserSocials'
import TeaserContent, {
  TeaserContentProps,
} from '@components/modules/teaserContent'
import CoreValues, { CoreValuesProps } from '@components/modules/coreValues'
import Management, { ManagementProps } from '@components/modules/management'
import ContentHighlight, {
  ContentHighlightProps,
} from '@components/modules/contentHighlight'
import Intro, { IntroProps } from '@components/modules/intro'
import History, { HistoryProps } from '@components/modules/history'
import Benefits, { BenefitsProps } from '@components/modules/benefits'
import Jobportal, { JobportalProps } from '@components/modules/jobportal'
import VideoHighlight, {
  VideoHighlightProps,
} from '@components/modules/videoHighlight'
import VideoYouTubeSwitch, {
  VideoYouTubeSwitchProps,
} from '@components/core/media/videoyoutubeswitch'

type PageModuleParserType = {
  __typename: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: (mod: any) => LAW.Props.ModuleUnion
}

export const getPropAsset = (
  value:
    | LAW.Contentful.IAsset
    | LAW.Contentful.IAssetImage
    | LAW.Contentful.IAssetSvg
): LAW.Props.Image | LAW.Props.Svg | LAW.Props.Video => {
  const isImage = value?.file?.contentType.startsWith('image/')
  const asset = {
    title: value?.title,
    description: value?.description,
    file: value?.file,
  }
  if (isImage && (value as LAW.Contentful.IAssetSvg).svg) {
    return {
      ...asset,
      content: (value as LAW.Contentful.IAssetSvg).svg.content,
    }
  }

  return isImage && (value as LAW.Contentful.IAssetImage).gatsbyImageData
    ? {
        ...asset,
        data: getImage((value as LAW.Contentful.IAssetImage).gatsbyImageData),
      }
    : asset
}

const PageModuleParser: Array<PageModuleParserType> = [
  {
    __typename: 'ContentfulModuleStage',
    component: Stage,
    props: (mod: LAW.Contentful.IModuleStage): StageProps => {
      const { image, imageMobile, ...rest } = mod
      return {
        ...rest,
        image: image ? getPropAsset(image) : undefined,
        imageMobile: imageMobile ? getPropAsset(imageMobile) : undefined,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserSection',
    component: TeaserSection,
    props: (mod: LAW.Contentful.IModuleTeaserSection): TeaserSectionProps => {
      const { linkPage, copy, image, ...rest } = mod
      return {
        ...rest,
        copy: copy,
        image: image && (getAssetProp(image) as LAW.Props.Image),
        linkTo: linkPage?.fields?.linkTo,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserRelated',
    component: TeaserRelated,
    props: (mod: LAW.Contentful.IModuleTeaserRelated): TeaserRelatedProps => {
      const { relatedTeaserItem, ...rest } = mod
      return {
        ...rest,
        items: relatedTeaserItem?.map(({ image, linkPage, ...rest }) => ({
          ...rest,
          image: getAssetProp(image) as LAW.Props.Image,
          linkTo: linkPage?.fields?.linkTo,
        })),
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserCarouselMedia',
    component: TeaserCarousel,
    props: (
      mod: LAW.Contentful.IModuleTeaserCarouselMedia
    ): TeaserCarouselProps => {
      const { mediaCards, linkPage, ...rest } = mod
      return {
        ...rest,
        linkTo: linkPage?.fields?.linkTo,
        items: mediaCards.map(
          ({ media, videoPreviewImage, linkPage, ...rest }) => ({
            ...rest,
            media: media ? (getAssetProp(media) as LAW.Props.Image) : undefined,
            poster:
              videoPreviewImage &&
              (getAssetProp(videoPreviewImage) as LAW.Props.Image),
            linkTo: linkPage?.fields?.linkTo,
          })
        ),
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserCarouselText',
    component: TeaserCarousel,
    props: (
      mod: LAW.Contentful.IModuleTeaserCarouselText
    ): TeaserCarouselProps => {
      const { textCards, linkPage, ...rest } = mod
      return {
        ...rest,
        linkTo: linkPage?.fields?.linkTo,
        items: textCards.map(({ linkPage, ...rest }) => ({
          ...rest,
          linkTo: linkPage?.fields?.linkTo,
        })),
      }
    },
  },
  {
    __typename: 'ContentfulModuleAccordion',
    component: Accordion,
    props: (mod: LAW.Contentful.IModuleAccordion): AccordionProps => {
      const { items, ...rest } = mod
      return {
        ...rest,
        items: items?.map(({ headline, ...rest }) => ({
          ...rest,
          title: headline,
        })),
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserSocials',
    component: TeaserSocials,
    props: (mod: LAW.Contentful.IModuleTeaserSocials): TeaserSocialsProps => {
      const { linkPage, linkPage2, linkPage3, ...rest } = mod
      return {
        ...rest,
        linkTo: linkPage?.fields?.linkTo,
        linkTo2: linkPage2?.fields?.linkTo,
        linkTo3: linkPage3?.fields?.linkTo,
      }
    },
  },
  {
    __typename: 'ContentfulModuleCoreValues',
    component: CoreValues,
    props: (mod: LAW.Contentful.IModuleCoreValues): CoreValuesProps => {
      const { items } = mod
      return {
        items: items.map(({ headline, copy, ...rest }) => ({
          ...rest,
          title: headline,
          content: copy ? getRichTextProp(copy) : undefined,
        })),
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserContent',
    component: TeaserContent,
    props: (mod: LAW.Contentful.IModuleTeaserContent): TeaserContentProps => {
      const { copy, linkPage, jobs, departments, items, ...rest } = mod
      return {
        ...rest,
        copy: copy,
        linkTo: linkPage?.fields?.linkTo,
        jobs: jobs?.map(({ linkPage, ...rest }) => ({
          ...rest,
          linkTo: linkPage?.fields?.linkTo,
        })),
        departments: departments?.map(({ ...rest }) => ({
          ...rest,
        })),
        items: items?.map(({ ...rest }) => ({
          ...rest,
        })),
      }
    },
  },
  {
    __typename: 'ContentfulModuleManagement',
    component: Management,
    props: (mod: LAW.Contentful.IModuleManagement): ManagementProps => {
      const { managementItem, ...rest } = mod
      return {
        ...rest,
        items: managementItem?.map(({ image, ...rest }) => ({
          ...rest,
          image: getAssetProp(image) as LAW.Props.Image,
        })),
      }
    },
  },
  {
    __typename: 'ContentfulModuleContentHighlight',
    component: ContentHighlight,
    props: (
      mod: LAW.Contentful.IModuleContentHighlight
    ): ContentHighlightProps => {
      const { ...rest } = mod
      return {
        ...rest,
      }
    },
  },
  {
    __typename: 'ContentfulModuleIntro',
    component: Intro,
    props: (mod: LAW.Contentful.IModuleIntro): IntroProps => {
      const { techStack, checklist, ...rest } = mod
      return {
        ...rest,
        techStack: techStack?.techStack,
        checklist: checklist?.checklist,
      }
    },
  },
  {
    __typename: 'ContentfulModuleHistory',
    component: History,
    props: (mod: LAW.Contentful.IModuleHistory): HistoryProps => {
      const { image, imagePositionRight, ...rest } = mod
      return {
        ...rest,
        image: image && (getAssetProp(image) as LAW.Props.Image),
        imageRight: imagePositionRight,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBenefits',
    component: Benefits,
    props: (mod: LAW.Contentful.IModuleBenefits): BenefitsProps => {
      return mod
    },
  },
  {
    __typename: 'ContentfulModuleJobportal',
    component: Jobportal,
    props: (mod: LAW.Contentful.IModuleJobportal): JobportalProps => {
      const { ...rest } = mod
      return {
        ...rest,
      }
    },
  },
  {
    __typename: 'ContentfulModuleVideo',
    component: VideoYouTubeSwitch,
    props: (mod: VideoYouTubeSwitchProps): VideoYouTubeSwitchProps => mod,
  },
  {
    __typename: 'ContentfulModuleVideoHighlight',
    component: VideoHighlight,
    props: (mod: LAW.Contentful.IModuleVideoHighlight): VideoHighlightProps => {
      const { videoHighlightDescription, ...rest } = mod
      return {
        description: videoHighlightDescription,
        ...rest,
      }
    },
  },
]

export default PageModuleParser
