import React, { ReactElement, useMemo, useEffect } from 'react'
import { graphql } from 'gatsby'
import PageModuleArray from '@components/system/PageModuleArray'
import useLangSwitch from '@components/system/hooks/useLangSwitch'

export default function PageGeneric({
  data,
}: LAW.Contentful.IPageGeneric): ReactElement {
  const modules = useMemo(() => data.contentfulPageGeneric.modules, [])
  const { setLangSwitchLink } = useLangSwitch()

  useEffect(
    () =>
      setLangSwitchLink(data.otherLangContentfulPageGeneric?.fields?.fullPath),
    [data.otherLangContentfulPageGeneric?.fields?.fullPath]
  )

  return <>{!!modules && <PageModuleArray modulearray={modules} />}</>
}

export const pageQuery = graphql`
  query PageGenericByPath(
    $id: String!
    $contentful_id: String!
    $locale: String!
  ) {
    contentfulPageGeneric(id: { eq: $id }, node_locale: { eq: $locale }) {
      modules {
        ...ContentfulModulesFragment
      }
    }
    otherLangContentfulPageGeneric: contentfulPageGeneric(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $locale }
    ) {
      fields {
        fullPath
      }
    }
  }
`
