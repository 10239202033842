import React, { ReactElement, useEffect } from 'react'
import { Typography, Theme, useMediaQuery } from '@mui/material'

import CoreValuesItem from '@components/modules/coreValues/item'
import Section from '@components/core/container/section'

export type CoreValuesItemProps = {
  title: string
  content: string
}

export type CoreValuesProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    items: CoreValuesItemProps[]
  }

export default function CoreValues({ items }: CoreValuesProps): ReactElement {
  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('md')
  )

  const [expanded, setExpanded] = React.useState<string | false>('panel0')

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  useEffect(() => {
    if (isMobile === true) {
      setExpanded(false)
    }
  }, [isMobile])

  return (
    <Section roundedBorders withShadow maxWidth={false}>
      {items.map((itemProps, index) => (
        <CoreValuesItem
          key={index}
          id={`panel${index}`}
          title={itemProps.title}
          expanded={expanded === `panel${index}`}
          onChange={handleChange}
        >
          <Typography component="div">{itemProps.content}</Typography>
        </CoreValuesItem>
      ))}
    </Section>
  )
}
