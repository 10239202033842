import React from 'react'

import { Box, Theme } from '@mui/material'

import Section from '@components/core/container/section'
import Image from '@components/core/media/image'
import TextBlock from '@components/core/text/block'

interface ITeaserSection extends LAW.Props.IDefault {
  caption?: string
  headline: string
  callToAction?: string
  copy?: LAW.Contentful.BasicRichTextType
  image: LAW.Props.Image
  imagePositionRight?: boolean
  linkTo?: LAW.Props.LinkTo
}

export type TeaserSectionProps = LAW.Contentful.INodeDefaults & ITeaserSection

export default function TeaserSection({
  children,
  caption,
  headline,
  copy,
  image,
  imagePositionRight,
  linkTo,
  callToAction,
}: ITeaserSection): React.ReactElement {
  return (
    <Section
      roundedBorders
      withShadow
      sx={{
        py: { xs: 20, md: 30 },
        '> .MuiContainer-root ': {
          padding: 0,
        },
      }}
      maxWidth="xl"
    >
      <Box
        sx={(muiTheme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          [muiTheme.breakpoints.up('md')]: {
            flexDirection: 'row',
          },
        })}
      >
        <Box
          sx={(muiTheme: Theme) => ({
            display: 'flex',
            alignItems: 'center',
            px: 6,
            pb: 12,
            order: 1,
            [muiTheme.breakpoints.up('md')]: {
              px: 8,
              pb: 0,
              width: '50%',
              order: !imagePositionRight ? 2 : 1,
            },
            [muiTheme.breakpoints.up('lg')]: {
              pl: !imagePositionRight ? 15 : 17,
              pr: !imagePositionRight ? 17 : 15,
            },
            [muiTheme.breakpoints.up('xl')]: {
              pl: !imagePositionRight ? 29 : 34,
              pr: !imagePositionRight ? 34 : 29,
            },
            [muiTheme.breakpoints.up('xxl')]: {
              pl: !imagePositionRight ? 29 : 26,
              pr: !imagePositionRight ? 36 : 29,
            },
          })}
        >
          <TextBlock
            caption={caption}
            headline={headline}
            copy={copy}
            linkTo={linkTo}
            callToAction={callToAction}
            noPadding
          />
        </Box>
        <Box
          sx={(muiTheme: Theme) => ({
            order: 2,
            [muiTheme.breakpoints.up('md')]: {
              width: '50%',
              order: !imagePositionRight ? 1 : 2,
            },
          })}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!!image && (
              <Image
                data={image.data}
                alt={image.description}
                sx={(muiTheme: Theme) => ({
                  width: '100%',
                  '& img': {
                    [muiTheme.breakpoints.up('md')]: !imagePositionRight
                      ? {
                          borderTopRightRadius: '16px',
                          borderBottomRightRadius: '16px',
                        }
                      : {
                          borderTopLeftRadius: '16px',
                          borderBottomLeftRadius: '16px',
                        },
                    [muiTheme.breakpoints.up('xxl')]: !imagePositionRight
                      ? {
                          borderTopLeftRadius: '16px',
                          borderBottomLeftRadius: '16px',
                        }
                      : {
                          borderTopRightRadius: '16px',
                          borderBottomRightRadius: '16px',
                        },
                  },
                })}
              />
            )}
          </Box>
        </Box>
      </Box>

      {children}
    </Section>
  )
}
