import React, { useCallback } from 'react'

export default function useAccordionControls(
  activeId: number | string | boolean
): [
  string | number | boolean,
  (
    event: React.SyntheticEvent<Element, Event>,
    id: number,
    isExpanded: boolean
  ) => void
] {
  const [activeAccordionId, setActiveAccordionId] = React.useState<
    boolean | number | string
  >(activeId)

  const handleAccordionChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      id: number | string,
      isExpanded: boolean
    ) => {
      setActiveAccordionId(isExpanded ? id : false)
      setTimeout(() => {
        window.scrollBy({
          top: (event.target as HTMLElement).getBoundingClientRect().top - 250,
          behavior: 'smooth',
        })
      }, 350)
    },
    []
  )

  return [activeAccordionId, handleAccordionChange]
}
