import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import renderRichText from '@utils/richtext'
import AccordionItem from '@components/modules/accordion/item'
import useAccordionControls from '@components/system/hooks/useAccordionControls'
import Section from '@components/core/container/section'
import TextBlock from '@components/core/text/block'

export type AccordionItemProps = {
  title: string
  copy: LAW.Contentful.BasicRichTextType
  columns: number
}

export type AccordionProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    items: AccordionItemProps[]
  }

export default function Accordion({
  headline,
  items,
}: AccordionProps): ReactElement {
  const [activeAccordionId, handleAccordionChange] = useAccordionControls(false)

  return (
    <Section
      roundedBorders
      withShadow
      sx={{
        py: { xs: 20, md: 30 },
      }}
      maxWidth="xl"
    >
      <TextBlock headline={headline} />

      <Box
        sx={{
          ml: { xs: 0, lg: 32 },
          mr: { xs: 0, lg: 32 },
          '> .MuiAccordion-root + .MuiAccordion-root': {
            mt: 0.5,
          },
        }}
      >
        {items.map((itemProps, index) => {
          if (!itemProps.copy) return

          return (
            <AccordionItem
              columns={itemProps.columns}
              key={index}
              title={itemProps.title}
              expanded={activeAccordionId === index}
              onChange={(event, isExpanded) =>
                handleAccordionChange(event, index, isExpanded)
              }
            >
              {renderRichText(itemProps.copy)}
            </AccordionItem>
          )
        })}
      </Box>
    </Section>
  )
}
