import React, { useState } from 'react'
import {
  Grid,
  useMediaQuery,
  useTheme,
  Button as MuiButton,
} from '@mui/material'

import useGlobalText from '@components/system/hooks/useGlobalText'
import Section from '@components/core/container/section'
import TextBlock from '@components/core/text/block'

import BenefitCard, { IBenefitsCard } from './card'

export type BenefitsProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    items: Array<IBenefitsCard>
  }

export default function Benefits({
  headline,
  items,
}: BenefitsProps): React.ReactElement {
  const theme = useTheme()
  const { getText } = useGlobalText()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))
  const [fullList, setFullList] = useState(false)

  const slicedItems = items?.slice(0, 5)

  function handleClick() {
    setFullList(!fullList)
  }

  return (
    <Section
      roundedBorders
      withShadow
      sx={{
        py: { xs: 20, md: 30 },
      }}
      maxWidth="xl"
    >
      <TextBlock headline={headline} />

      <Grid
        container
        columnSpacing={8}
        rowSpacing={{ xs: 8, lg: 12 }}
        sx={{
          display: 'flex',
        }}
      >
        {(fullList || isLarge ? items : slicedItems).map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <BenefitCard index={i} {...item} />
          </Grid>
        ))}
      </Grid>

      {!isLarge && !fullList && (
        <MuiButton
          variant="contained"
          color="primary"
          onClick={handleClick}
          sx={{
            mt: 8,
          }}
        >
          {getText('btn.show.all')}
        </MuiButton>
      )}
    </Section>
  )
}
