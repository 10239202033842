import React, { ReactElement } from 'react'
import {
  Accordion as AccordionMUI,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
  Theme,
} from '@mui/material'
import clsx from 'clsx'
import AccordionIcon from '@components/core/ui/accordionIcon'

export type AccordionProps = LAW.Props.IDefault & {
  /**
   * The Title shown
   * Displays Prop Tables with these components
   * @default null
   */
  title: string
  variant?: 'outlined'
  /**
   * The Icon set used by the Component
   * @default null
   */
  expanded?: boolean
  defaultExpanded?: boolean
  columns: number
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void
}

/**
 * MUI Accordion Wrapper
 */
export default function AccordionItem({
  title,
  children,
  expanded,
  defaultExpanded,
  columns,
  variant,
  onChange,
  sx,
}: AccordionProps): ReactElement {
  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('lg')
  )

  return (
    <AccordionMUI
      className={clsx({
        'MuiAccordion-outlined': variant === 'outlined',
      })}
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={onChange}
      sx={(theme) => ({
        ...sx,
        [theme.breakpoints.up('lg')]: {
          '&:hover': {
            borderColor: 'background.dark',
          },
        },
      })}
    >
      <AccordionSummary
        expandIcon={<AccordionIcon active={expanded} />}
        sx={{
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: 'common.white',
          },
          '.Mui-expanded': {
            transform: 'none !important',
          },
        }}
      >
        <Typography variant="h3" component="span">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: {
            columns: isMobile ? 1 : columns,
          },
          'p:empty': {
            height: 0,
            margin: 0,
            padding: 0,
          },

          '.MuiTypography-root': {
            mb: { xs: '24px', lg: '48px' },
          },
          ' p:last-child': {
            mb: 0,
          },
          '& .gatsby-image-wrapper': {
            borderRadius: { xs: '8px', lg: '12px' },
            willChange: 'transform', // safari fix
          },
          pb: { xs: '8px', lg: '24px' },
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMUI>
  )
}
