import React, { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'
import Icon, { IconNamesType } from '../icon'
import theme from '@config/theme'

export type IconListProps = LAW.Props.IDefault & {
  headline?: string
  items: string[]
  icon?: IconNamesType
}

/**
 * MUI Accordion Wrapper
 */
export default function IconList({
  headline,
  items,
  icon = 'Check',
  sx = {},
}: IconListProps): ReactElement {
  return (
    <Box
      sx={[
        {
          padding: { xs: 5, md: 6 },
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: 2,
        },
        sx as LAW.Props.SxInArray,
      ]}
    >
      {headline && (
        <Typography variant="h4" sx={{ mb: { xs: 5, md: 6 } }}>
          {headline}
        </Typography>
      )}
      {items.map((entry, index) => (
        <Box
          key={index}
          sx={{
            mt: { xs: 3, md: 4 },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon name={icon} sx={{ mr: 2 }} fontSize={'medium'} />
          <Typography variant="chip">{entry}</Typography>
        </Box>
      ))}
    </Box>
  )
}
