import React, { useEffect, useState, useRef, useContext } from 'react'
import SwiperClass from 'swiper/types/swiper-class'
import useInViewAnim from '@components/system/hooks/useInViewAnim'

import {
  Box,
  useMediaQuery,
  useTheme,
  Typography,
  Container,
} from '@mui/material'

import renderRichText from '@utils/richtext'
import ThemeContext from '@providers/themeProvider'

import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import Carousel from '@components/core/ui/carousel'
import TeaserSection from '@components/modules/teaserSection'

import HistoryItem, { IHistoryItem } from './item'

interface ICarouselCard {
  year: number
  milestoneItems: Array<IHistoryItem>
}

export type HistoryProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline?: string
    image: LAW.Props.Image
    imageRight?: boolean
    copy?: LAW.Contentful.BasicRichTextType
    sectionHeadline: string
    items?: Array<IHistoryItem>
  }

export default function History({
  headline,
  sectionHeadline,
  image,
  imageRight,
  copy,
  items,
}: HistoryProps): React.ReactElement {
  const theme = useTheme()
  const { theme: pageTheme } = useContext(ThemeContext)
  const [textInViewRef, textInViewSX] = useInViewAnim('bottom-to-top')
  const [slideInViewRef, slideInViewSX] = useInViewAnim('bottom-to-top')

  const [isBeginning, setIsBeginning] = useState<boolean>(true)
  const [isEnd, setIsEnd] = useState<boolean>(false)
  const [currentYear, setCurrentYear] = useState<number>(0)
  const [height, setHeight] = useState<number>(0)
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const showNavigationButtons = isLarge && items && items.length > 7
  const [swiper, setSwiper] = useState<SwiperClass>()
  const contentRef = useRef(null)

  const sortedhistoryItems = items?.slice().sort((a, b) => b.year - a.year)

  const swiperItems: Array<ICarouselCard> = []

  sortedhistoryItems?.forEach((item) => {
    const existingYear: ICarouselCard | undefined = swiperItems.find(
      (swiperItem) => swiperItem.year === item.year
    )

    if (existingYear) {
      existingYear.milestoneItems?.push(item)
    } else {
      swiperItems.push({
        year: item.year,
        milestoneItems: [item],
      })
    }
  })

  useEffect(() => {
    if (contentRef.current !== null) {
      setHeight(
        (contentRef.current as HTMLDivElement).getBoundingClientRect().height
      )
    }
  }, [])

  useEffect(() => {
    if (typeof swiper?.allowTouchMove === 'boolean') {
      swiper.allowTouchMove = !isLarge
    }
  }, [isLarge])

  function onSwiper(swiper: SwiperClass): void {
    setSwiper(swiper)
  }

  return (
    <>
      <TeaserSection
        headline={sectionHeadline}
        image={image}
        imagePositionRight={imageRight}
      >
        {(headline || copy) && (
          <Container
            maxWidth="md"
            sx={{
              mt: { xs: 20, xl: 30 },
            }}
          >
            <Box
              ref={textInViewRef}
              sx={{
                ...textInViewSX,
              }}
            >
              {headline && (
                <Typography
                  variant="h3"
                  sx={{
                    mb: { xs: 6, xl: 8 },
                  }}
                >
                  {headline}
                </Typography>
              )}

              {copy && (
                <Box
                  sx={{
                    mb: { xs: 20, xl: 30 },
                  }}
                >
                  {renderRichText(copy)}
                </Box>
              )}
            </Box>
          </Container>
        )}
      </TeaserSection>

      {!!items && items.length > 1 && (
        <Section
          roundedBorders
          maxWidth="md"
          theme="dark"
          sx={{
            pt: { xs: 12, md: 20 },
            pb: { xs: 24, md: 30 },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              mx: { xs: -6, md: -8, lg: 0 },
              mb: { xs: 16, lg: 29 },
            }}
          >
            {showNavigationButtons && (
              <Button
                variant="round"
                icon="ArrowLeft"
                disabled={isBeginning}
                onClick={() => swiper?.slidePrev()}
                sx={{
                  position: 'absolute',
                  top: -14,
                  left: { lg: -108, xl: -200 },
                  zIndex: 1,
                  backgroundColor: '#ffffff',
                  '& svg': {
                    color: theme.palette.grey[900],
                  },
                  ':disabled': {
                    color: theme.palette.grey[300],
                    backgroundColor: theme.palette.grey[700],
                  },
                }}
              />
            )}
            <Carousel
              onSwiper={onSwiper}
              onActiveIndexChange={({
                isBeginning,
                isEnd,
              }: {
                isBeginning: boolean
                isEnd: boolean
              }) => {
                setIsBeginning(isBeginning)
                setIsEnd(isEnd)
              }}
              slidesPerView={'auto'}
              spaceBetween={24}
              slidesOffsetBefore={24}
              slidesOffsetAfter={24}
              breakpoints={{
                768: {
                  slidesPerView: 7,
                  slidesOffsetBefore: 32,
                  slidesOffsetAfter: 32,
                  spaceBetween: 24,
                },
                1024: {
                  slidesPerView: 7,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                  spaceBetween: 32,
                },
                1280: {
                  slidesPerView: 7,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                  spaceBetween: 36,
                },
              }}
            >
              {swiperItems.map((item, i) => (
                <Typography
                  key={i}
                  component="div"
                  variant="h3"
                  sx={(theme) => ({
                    position: 'relative',
                    display: 'inline-block',
                    cursor: 'pointer',
                    pb: 2,
                    transition: `all .3s ${theme.transitions.easing.easeInOut}`,
                    ...(currentYear === i && {
                      color:
                        pageTheme === 'sustainability'
                          ? theme.palette.sustainability.main
                          : 'primary.main',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: 2,
                        backgroundColor:
                          pageTheme === 'sustainability'
                            ? theme.palette.sustainability.main
                            : 'primary.main',
                        transition: `width .3s ${theme.transitions.easing.easeInOut}`,
                      },
                    }),
                    [theme.breakpoints.up('lg')]: {
                      '&:hover': {
                        color:
                          pageTheme === 'sustainability'
                            ? theme.palette.sustainability.main
                            : 'primary.main',
                      },
                    },
                  })}
                  onClick={() => {
                    setCurrentYear(i)
                    swiper?.slideTo(i)
                  }}
                >
                  {item.year}
                </Typography>
              ))}
            </Carousel>
            {showNavigationButtons && (
              <Button
                variant="round"
                icon="ArrowRight"
                disabled={isEnd}
                onClick={() => swiper?.slideNext()}
                sx={{
                  position: 'absolute',
                  top: -14,
                  right: { lg: -108, xl: -200 },
                  zIndex: 1,
                  width: theme.spacing(17),
                  height: theme.spacing(17),
                  ml: 'auto',
                  backgroundColor: '#ffffff',
                  '& svg': {
                    color: theme.palette.grey[900],
                  },
                  ':disabled': {
                    color: theme.palette.grey[300],
                    backgroundColor: theme.palette.grey[700],
                  },
                }}
              />
            )}
          </Box>

          <Box ref={contentRef}>
            <Box
              ref={slideInViewRef}
              sx={{
                ...slideInViewSX,
                mt: 2,
                position: 'relative',
                display: 'flex',
                overflow: 'hidden',
                height: height ? height : 'auto',
              }}
            >
              {swiperItems?.map((item, i) => {
                return item.milestoneItems ? (
                  <Box
                    key={`carousel-item-${i}`}
                    sx={{
                      mt: 2,
                      opacity: currentYear === i ? 1 : 0,
                      transition:
                        currentYear === i ? 'all 0.4s ease-in' : 'none',
                      flex: '0 0 100%',
                      position: height ? 'absolute' : 'static',
                      transform: {
                        xs: `translateY(${currentYear === i ? 0 : 15}px)`,
                        lg: `translateY(${currentYear === i ? 0 : 30}px)`,
                      },
                    }}
                  >
                    {item.milestoneItems.map((item, i) => {
                      return (
                        <HistoryItem key={`carousel-item-${i}`} {...item} />
                      )
                    })}
                  </Box>
                ) : null
              })}
            </Box>
          </Box>
        </Section>
      )}
    </>
  )
}
