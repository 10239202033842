import { SxProps, Theme } from '@mui/material'
import { useInView } from 'react-intersection-observer'

export type useInViewProps = {
  delay?: number
}

export default function useInViewAnim(
  type?: 'bottom-to-top' | 'left-to-right' | 'right-to-left',
  options?: useInViewProps
): [(node?: Element | null) => void, SxProps<Theme>] {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
    rootMargin: '0px',
  })

  const BaseSxProps: SxProps = {
    transition: `all 1s ease-in-out ${options?.delay || 0}s`,
    opacity: inView ? 1 : 0,
  }

  const BottomToTopSxProps: SxProps = {
    ...BaseSxProps,
    transform: {
      xs: `translateY(${inView ? 0 : 15}px)`,
      lg: `translateY(${inView ? 0 : 30}px)`,
    },
  }

  const LeftToRightSxProps: SxProps = {
    ...BaseSxProps,
    transform: {
      xs: `translateX(-${inView ? 0 : 15}px)`,
      lg: `translateX(-${inView ? 0 : 30}px)`,
    },
  }

  const RightToLeftSxProps: SxProps = {
    ...BaseSxProps,
    transform: {
      xs: `translateX(${inView ? 0 : 15}px)`,
      lg: `translateX(${inView ? 0 : 30}px)`,
    },
  }

  let inViewSX = BottomToTopSxProps

  if (type === 'left-to-right') {
    inViewSX = LeftToRightSxProps
  } else if (type === 'right-to-left') {
    inViewSX = RightToLeftSxProps
  }

  return [ref, inViewSX]
}
