import React, { ReactElement, useContext } from 'react'
import {
  Accordion as AccordionMUI,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material'
import AccordionIcon from '@components/core/ui/accordionIcon'

import ThemeContext from '@providers/themeProvider'

export type CoreValuesProps = LAW.Props.IDefault & {
  /**
   * The Title shown
   * Displays Prop Tables with these components
   * @default null
   */
  title: string
  /**
   * The Icon set used by the Component
   * @default null
   */
  expanded: boolean
  defaultExpanded: boolean
  onChange: (
    panel: string
  ) => (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void
  id: string
}

/**
 * MUI Accordion Wrapper
 */
export default function CoreValuesItem({
  title,
  children,
  expanded,
  defaultExpanded,
  id,
  onChange,
}: CoreValuesProps): ReactElement {
  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('md')
  )

  const { theme: pageTheme } = useContext(ThemeContext)

  return (
    <AccordionMUI
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={onChange(id)}
      sx={{
        borderRadius: '0px',
        borderTopLeftRadius: { xs: '32px', md: '40px' },
        borderTopRightRadius: { xs: '32px', md: '40px' },
        border: 'none',
        color: 'common.white',

        '& span': {
          fontSize: { xs: '24px', md: '32px' },
          lineHeight: { xs: '32px', md: '40px' },
          transition: 'font-size .5s ease',
        },
        '& p': {
          fontSize: { xs: '16px', md: '18px' },
          fontWeight: '500',
          lineHeight: { xs: '28px', md: '30px' },
          maxWidth: { xs: '100%', md: '75%' },
        },
        '&.MuiPaper-root': {
          marginBottom: expanded ? -20 : -10,
          paddingTop: { xs: '40px', md: '48px' },
          paddingBottom: expanded ? '136px' : '96px',
          '&:last-of-type': {
            paddingBottom: expanded ? '136px' : '132px',
          },
          transition: 'all .5s ease',
          paddingLeft: { xs: null, md: '136px' },
          background:
            pageTheme === 'sustainability'
              ? 'linear-gradient(180deg, rgba(0,117,75,1) 60%,  rgba(0,82,45,1) 100%, rgba(0,117,75,1) 0%)'
              : 'linear-gradient(180deg, rgba(218,52,78,1) 60%,  rgba(178,12,38,1) 100%, rgba(218,52,78,1) 0%)',
        },
      }}
    >
      <AccordionSummary
        sx={{
          '.Mui-expanded': {
            transform: 'none !important',
          },
        }}
        expandIcon={
          isMobile ? <AccordionIcon active={expanded} color="white" /> : null
        }
      >
        <Typography variant="h3" component="span">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '& p': { marginBottom: '0px' },
          transition: 'all 0.4s ease-in-out 0s',
          opacity: expanded ? 1 : 0,
          transform: {
            xs: `translateY(${expanded ? 0 : 15}px)`,
            lg: `translateY(${expanded ? 0 : 30}px)`,
          },
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMUI>
  )
}
