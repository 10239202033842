import React, { ReactElement, useContext } from 'react'
import { Typography, styled } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'

import ThemeContext from '@providers/themeProvider'

export type TeaserCarouselTextItemProps = LAW.Props.IDefault & {
  caption?: string
  headline?: string
  copy: string
  linkTo?: LAW.Props.LinkTo
  type?: string
}

export default function TeaserCarouselTextItem({
  type,
  caption,
  headline,
  copy,
  linkTo,
}: TeaserCarouselTextItemProps): ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)
  const SxLink = linkTo ? styled(GatsbyLink)({}) : styled('div')({})

  return (
    <SxLink
      to={linkTo?.url as string}
      sx={(theme) => ({
        textDecoration: 'none',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: type === 'facts' ? 'space-between' : 'start',
        p: { xs: 5, md: 6 },
        border: `1px solid ${theme.palette.grey[300]}`,
        transition: `border .3s ${theme.transitions.easing.easeInOut}`,
        borderRadius: 2,
        boxSizing: 'border-box',
        [theme.breakpoints.up('lg')]: {
          '&:hover': {
            '& > div': {
              borderColor:
                type === 'facts' ? null : theme.palette.secondary.main,
              cursor: type === 'facts' ? null : 'pointer',
            },
          },
        },
      })}
    >
      {type === 'facts' ? (
        <>
          {caption && (
            <Typography
              variant="fact"
              component="p"
              sx={{
                color:
                  pageTheme === 'sustainability'
                    ? 'sustainability.main'
                    : 'primary.main',
                mb: { xs: 5, lg: 6 },
              }}
            >
              {caption}
            </Typography>
          )}
          <Typography component="p" variant="h3">
            {copy}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">{headline}</Typography>
          <Typography
            variant={'body2'}
            sx={{
              mt: { xs: 4, md: 6 },
            }}
          >
            {copy}
          </Typography>
        </>
      )}
    </SxLink>
  )
}
