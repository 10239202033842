import React, { useState, useContext } from 'react'
import { Box, Typography, styled } from '@mui/material'

import useInViewAnim from '@components/system/hooks/useInViewAnim'
import ThemeContext from '@providers/themeProvider'

export interface IBenefitsCard
  extends LAW.Props.IDefault,
    LAW.Contentful.INodeDefaults {
  headline: string
  copyText: string
  image: LAW.Props.Image
  index: number
}

export default function BenefitCard({
  headline,
  copyText,
  image,
  index,
}: IBenefitsCard): React.ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)
  const [showCopy, setShowCopy] = useState(false)
  const [teaserInViewRef, teaserInViewSX] = useInViewAnim('bottom-to-top', {
    delay: 0.3 * (index % 2),
  })

  const SxSvg = styled('img')({})

  function toggleCard(open: boolean) {
    setShowCopy(open)
  }

  return (
    <Box ref={teaserInViewRef} sx={teaserInViewSX}>
      <Box
        onMouseOut={() => toggleCard(false)}
        onMouseOver={() => toggleCard(true)}
        onTouchStart={() => toggleCard(!showCopy)}
        sx={{
          border: '1px solid',
          transition: showCopy
            ? 'border .2s ease-in-out'
            : 'border .4s ease-in-out',
          borderColor:
            showCopy === true
              ? pageTheme === 'sustainability'
                ? 'sustainability.main'
                : 'primary.main'
              : 'background.secondary',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <Box>
          <SxSvg
            src={image?.file?.url}
            sx={{
              maxHeight: '320px',
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: { xs: '70%', md: '100%' },
              height: 'auto',
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            backgroundColor:
              pageTheme === 'sustainability'
                ? 'sustainability.main'
                : 'primary.main',
            height: '100%',
            opacity: showCopy ? 1 : 0,
            transition: showCopy
              ? 'all .2s ease-in-out'
              : 'all .4s ease-in-out',
          }}
        >
          <Typography
            variant="benefit"
            component="p"
            sx={{
              transition: showCopy ? `all .4s ease-in-out` : 'none',
              opacity: showCopy ? 1 : 0,
              transform: {
                xs: `translateY(${showCopy ? 0 : 15}px)`,
                lg: `translateY(${showCopy ? 0 : 30}px)`,
              },
              margin: { xs: 6, md: 8 },
            }}
          >
            {copyText}
          </Typography>
        </Box>
      </Box>

      {!!headline && (
        <Typography
          variant="h3"
          sx={{
            mt: { xs: 4, lg: 6 },
          }}
        >
          {headline}
        </Typography>
      )}
    </Box>
  )
}
