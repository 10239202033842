import React, { ReactElement, useRef, useContext } from 'react'
import { Box, Container, styled, Theme } from '@mui/material'
import clsx from 'clsx'

import ThemeContext from '@providers/themeProvider'

import Image from '@components/core/media/image'
import useScrollAnim from '@components/system/hooks/useScrollAnim'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export type SectionProps = LAW.Props.IDefault & {
  theme?: LAW.Contentful.Theme
  roundedBorders?: boolean
  withShadow?: boolean
  imageEagerLoading?: boolean
  background?: LAW.Props.Video | LAW.Props.Image
  backgroundMobile?: LAW.Props.Video | LAW.Props.Image
  objectFit?: 'contain' | 'fill' | 'none' | 'cover' | 'scale-down'
  objectPosition?: string
  onBackgroundComplete?: () => void
  paddingTop?: LAW.Props.BoxPadding
  paddingBottom?: LAW.Props.BoxPadding
  maxWidth?: 'md' | 'lg' | 'xl' | false
  full?: boolean
}

function getThemeStyles(muiTheme: Theme, theme?: LAW.Contentful.Theme) {
  switch (theme) {
    case 'light':
      return {
        backgroundColor: muiTheme.palette.background.light,
      }
    case 'red':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: muiTheme.palette.background.red,
      }
    case 'green':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: muiTheme.palette.background.green,
      }
    case 'dark':
      return {
        color: muiTheme.palette.text.inverted,
        backgroundColor: muiTheme.palette.background.dark,
      }
    default:
      return {
        backgroundColor: muiTheme.palette.background.main,
      }
  }
}

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'moduleTheme',
})<{
  moduleTheme?: LAW.Contentful.Theme
  roundedBorders?: boolean
  withShadow?: boolean
}>(({ theme, moduleTheme, roundedBorders, withShadow }) => ({
  overflowX: 'hidden',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginTop: roundedBorders ? '-32px' : 0,
  borderTopLeftRadius: roundedBorders ? '32px' : 0,
  borderTopRightRadius: roundedBorders ? '32px' : 0,
  boxShadow: withShadow
    ? '0 -6px 6px 0 rgba(27, 30, 32, 0.02), 0 -24px 24px 0 rgba(27, 30, 32, 0.05)'
    : 'none',
  [theme.breakpoints.up('md')]: {
    borderTopLeftRadius: roundedBorders ? '40px' : 0,
    borderTopRightRadius: roundedBorders ? '40px' : 0,
    boxShadow: withShadow
      ? '0 -8px 8px 0 rgba(27, 30, 32, 0.02), 0 -32px 32px 0 rgba(27, 30, 32, 0.05)'
      : 'none',
  },
  ...getThemeStyles(theme, moduleTheme),
}))

export default function Section({
  theme,
  children,
  background,
  imageEagerLoading,
  backgroundMobile,
  roundedBorders,
  withShadow,
  onBackgroundComplete,
  maxWidth = 'lg',
  full,
  sx,
}: SectionProps): ReactElement {
  const sectionRef = useRef<HTMLElement>()
  useScrollAnim(sectionRef)
  const isBackgroundImage = background?.file?.contentType.startsWith('image')
  const className = clsx([theme && 'Mui-themed'])
  const { theme: pageTheme } = useContext(ThemeContext)

  return (
    <Wrapper
      ref={sectionRef}
      className={className}
      moduleTheme={
        pageTheme === 'sustainability' && theme === 'red' ? 'green' : theme
      }
      sx={sx}
      roundedBorders={roundedBorders}
      withShadow={withShadow}
    >
      <Container maxWidth={maxWidth} disableGutters={maxWidth === false}>
        {children as React.ReactNode}
      </Container>

      {background && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: -1,
          }}
        >
          {isBackgroundImage && (
            <Image
              {...(background as LAW.Props.Image)}
              sectionBackground={background as unknown as IGatsbyImageData}
              sectionBackgroundMobile={
                backgroundMobile as unknown as IGatsbyImageData
              }
              eager={imageEagerLoading}
              objectPosition={full ? 'top' : 'center'}
              sx={(muiTheme: Theme) => ({
                height: '100%',
                right: 0,
                [muiTheme.breakpoints.down('md')]: {
                  width: '100%',
                },
                [muiTheme.breakpoints.up('md')]: {
                  width: full === true ? '100%' : '50%',
                  marginLeft: 'auto',
                },
              })}
            />
          )}
          {!isBackgroundImage && (
            <Box
              component="video"
              autoPlay
              playsInline
              muted
              src={background?.file?.url}
              onEnded={onBackgroundComplete}
              sx={{
                objectFit: 'cover',
                objectPosition: 'center',
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </Box>
      )}
    </Wrapper>
  )
}
