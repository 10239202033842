import React, { ReactElement } from 'react'

import PageModule from './PageModule'

export type PageModuleArrayProps = LAW.Props.IDefault & {
  modulearray: Array<LAW.Contentful.ModuleUnion>
}

export default function PageModuleArray({
  className,
  modulearray,
}: PageModuleArrayProps): ReactElement {
  return (
    <>
      {modulearray.map((mod, i) => (
        <PageModule
          key={`${mod.id}${i}`}
          className={className}
          pagemodule={mod}
        />
      ))}
    </>
  )
}
