import React, { ReactElement } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { styled } from '@mui/material'

import SwiperCore, {
  SwiperOptions,
  Parallax,
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from 'swiper'

import { useIntl } from 'react-intl'

const SxSwiper = styled(Swiper)({
  overflow: 'hidden',
  '& .swiper-wrapper': {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
  },
  '& .swiper-container-android .swiper-slide, .swiper-wrapper': {
    transform: 'translate3d(0px, 0, 0)',
  },
  '& .swiper-slide': {
    flexShrink: 0,
    height: 'auto',
    position: 'relative',
    transitionProperty: 'transform',
  },
  '& .swiper-notification': {
    position: 'absolute',
    left: 0,
    top: 0,
    pointerEvents: 'none',
    opacity: 0,
    zIndex: -1000,
  },
})

export type CarouselProps = LAW.Props.IDefault &
  SwiperOptions & {
    hasPagination?: boolean
    paginationItems?: Array<string>
    onSwiper?: any
    onActiveIndexChange?: any
  }

export default function Carousel({
  children,
  hasPagination,
  paginationItems,
  sx = {},
  ...props
}: CarouselProps): ReactElement {
  const intl = useIntl()

  SwiperCore.use([Parallax, A11y, Autoplay, Navigation, Pagination, EffectFade])

  return (
    <SxSwiper
      pagination={
        hasPagination
          ? {
              clickable: true,
              renderBullet: function (index: number, className: string) {
                return `<div class="${className}">${
                  paginationItems ? paginationItems[index] : index + 1
                }</div>`
              },
            }
          : false
      }
      observer={true}
      observeParents={true}
      preventClicks={true}
      data-testid="carousel"
      a11y={{
        enabled: true,
        itemRoleDescriptionMessage: intl.formatMessage({
          id: 'carousel.label.itemRoleDescriptionMessage',
        }),
        paginationBulletMessage: intl.formatMessage({
          id: 'carousel.label.paginationBulletMessage',
        }),
        nextSlideMessage: intl.formatMessage({
          id: 'carousel.label.nextSlideMessage',
        }),
        prevSlideMessage: intl.formatMessage({
          id: 'carousel.label.prevSlideMessage',
        }),
        firstSlideMessage: intl.formatMessage({
          id: 'carousel.label.firstSlideMessage',
        }),
        lastSlideMessage: intl.formatMessage({
          id: 'carousel.label.lastSlideMessage',
        }),
        notificationClass: 'swiper-notification',
      }}
      {...props}
      sx={sx}
    >
      {(() => {
        return React.Children.map(
          children as React.ReactNode,
          (child, index) => {
            return React.cloneElement(
              <SwiperSlide key={`carousel-slide-${index}`} virtualIndex={index}>
                {child}
              </SwiperSlide>
            )
          }
        )
      })()}
    </SxSwiper>
  )
}
