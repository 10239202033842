import React, { ReactElement } from 'react'

import { Box } from '@mui/material'

interface IAccordionIcon {
  active: boolean
  color?: string
}

export default function AccordionIcon({
  active,
  color,
}: IAccordionIcon): ReactElement {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        height: theme.spacing(4.5),
        width: { xs: theme.spacing(5), lg: '30px' },
        marginLeft: theme.spacing(6),
      })}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: !!color ? color : theme.palette.text.primary,
          height: '2px',
          width: '100%',
          transform: 'translateY(6px) translateX(0px) rotate(90deg)',
          transition: '0.2s',
          ...(active === true && {
            width: '100%',
            transform: 'translateY(6px) translateX(0px) rotate(0deg)',
          }),
        })}
      ></Box>
      <Box
        sx={(theme) => ({
          backgroundColor: !!color ? color : theme.palette.text.primary,
          height: '2px',
          width: '100%',
          transform: 'translateY(4px) translateX(0px)',
        })}
      ></Box>
    </Box>
  )
}
