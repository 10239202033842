import React, { ReactElement, useContext } from 'react'
import { Box, Typography, Theme, styled } from '@mui/material'
import Link from '@components/core/ui/link'

import useInViewAnim from '@components/system/hooks/useInViewAnim'

import renderRichText from '@utils/richtext'
import ThemeContext from '@providers/themeProvider'

export type TeaserContentItemProps = LAW.Props.IDefault & {
  theme?: string
  caption?: string
  headline: string
  icon?: LAW.Props.Image
  copy?: string
  richtext?: LAW.Contentful.BasicRichTextType
  listType?: 'bullet' | 'checkmark'
  linkTo?: LAW.Props.LinkTo
  delay?: number
  animationType?: 'bottom-to-top' | 'right-to-left'
}

const SxSvg = styled('img')({})

export default function TeaserContentItem({
  theme = 'light',
  caption,
  headline,
  icon,
  copy,
  richtext,
  listType,
  linkTo,
  delay = 0,
  animationType = 'bottom-to-top',
  sx = {},
}: TeaserContentItemProps): ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)
  const [teaserInViewRef, teaserInViewSX] = useInViewAnim(animationType, {
    delay: delay,
  })

  return (
    <Box
      ref={teaserInViewRef}
      sx={[
        (muiTheme: Theme) => ({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 5,
          border: `1px solid ${
            theme !== 'light'
              ? 'rgba(255, 255, 255, 0.4)'
              : muiTheme.palette.grey[300]
          }`,
          borderRadius: 2,
          [muiTheme.breakpoints.up('lg')]: {
            p: 6,
            '&:hover': linkTo
              ? {
                  borderColor:
                    theme !== 'light'
                      ? 'rgba(255, 255, 255, 1)'
                      : muiTheme.palette.secondary.main,
                  cursor: 'pointer',
                }
              : undefined,
            ...teaserInViewSX,
          },
        }),

        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={(muiTheme: Theme) => ({
          mb: 8,
          [muiTheme.breakpoints.up('md')]: {
            mb: 12,
          },
          '&:last-child': {
            mb: 0,
          },
        })}
      >
        {caption && (
          <Typography
            variant="caption2"
            component="div"
            sx={(muiTheme: Theme) => ({
              color:
                pageTheme === 'sustainability'
                  ? muiTheme.palette.sustainability.main
                  : 'primary.main',
              mb: { xs: 4, md: 6 },
            })}
          >
            {caption}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3">{headline}</Typography>
          {icon && (
            <SxSvg
              src={icon?.file?.url}
              sx={{
                width: 96,
              }}
            />
          )}
        </Box>
        {(richtext || copy) && (
          <Typography
            variant={richtext ? 'body3' : 'body2'}
            component={richtext ? 'div' : 'p'}
            sx={(muiTheme: Theme) => ({
              mt: icon ? 8 : 4,
              '& p, li': { fontSize: 16 },
              [muiTheme.breakpoints.up('md')]: {
                mt: icon ? 12 : 6,
              },
            })}
          >
            {richtext
              ? renderRichText(richtext, false, false, listType === 'checkmark')
              : copy}
          </Typography>
        )}
      </Box>
      {linkTo && (
        <Link
          withIcon
          iconColor="currentColor"
          fontWeight="bold"
          to={linkTo?.url}
          sx={{
            fontSize: { xs: 'medium', lg: 'large' },
            display: 'flex',
            '&:after': {
              content: `''`,
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            },
          }}
        >
          {linkTo?.label}
        </Link>
      )}
    </Box>
  )
}
