import React, { useRef, useState, useContext } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material'
import Icon from '@components/core/ui/icon'

import ThemeContext from '@providers/themeProvider'

const SxVideo = styled('video')({
  maxWidth: '100%',
  borderRadius: '8px',
  verticalAlign: 'bottom',
})

export type VideoProps = LAW.Props.IDefault & {
  src: string
  poster: string
  videoItemIndex: string
}

export default function Video({
  src,
  poster,
  videoItemIndex,
}: VideoProps): React.ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoPlay, setVideoPlay] = useState(false)

  function toggleVideo() {
    if (!videoPlay) {
      videoRef.current?.play()
      setVideoPlay(true)
    }
  }

  return (
    <Box
      id={videoItemIndex}
      className="wrapper"
      onClick={() => {
        toggleVideo()
      }}
      sx={{
        position: 'relative',
      }}
    >
      <SxVideo ref={videoRef} src={src} poster={poster} controls></SxVideo>

      {!videoPlay && (
        <Box
          sx={{
            position: 'absolute',
            overflow: 'hidden',
            borderRadius: '8px',
            left: 0,
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {!!poster && <img width="100%" height="auto" src={poster} />}

          <Box
            className="playpause"
            sx={(theme) => ({
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: 0,
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              opacity: 1,
              transition: 'opacity 400ms',
              [theme.breakpoints.up('lg')]: {
                '&:hover': {
                  cursor: 'pointer',
                },
              },
            })}
          >
            <Icon
              name="PlayButton"
              fontSize="inherit"
              sx={{
                color:
                  pageTheme === 'sustainability'
                    ? 'sustainability.main'
                    : 'primary.main',
                fontSize: '64px',
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}
