import React from 'react'
import { Box, Typography } from '@mui/material'

import Section from '@components/core/container/section'

export type ContentHighlightProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    theme?: LAW.Contentful.Theme
  }

export default function ContentHighlight({
  headline,
  theme,
}: ContentHighlightProps): React.ReactElement {
  return (
    <Section
      theme={theme}
      roundedBorders
      withShadow
      sx={{
        pt: { xs: 16, md: 20 },
        pb: { xs: 25, md: 30 },
      }}
      maxWidth="xl"
    >
      <Box
        sx={{
          px: { xs: 0, md: 25 },
        }}
      >
        {headline && (
          <Typography
            variant="h3"
            sx={{
              textAlign: 'center',
            }}
          >
            {headline}
          </Typography>
        )}
      </Box>
    </Section>
  )
}
