/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useRef } from 'react'

import { AnimationContext } from '../providers/AnimationProvider'

export default function useAnim(
  ref?: React.MutableRefObject<HTMLElement | undefined>
) {
  const values = useContext(AnimationContext)
  const isDownScroll = useRef(true)

  useEffect(() => {
    isDownScroll.current = values.isDownScroll
  }, [values.isDownScroll])

  useEffect(() => {
    if (!ref?.current) return
    const animTriggerPoint = window.innerHeight
    let currTrans = 0

    /*
        Some devices has no continiously scroll behaviour.
        Depending on how "fast" you scroll, the ammount of "scroll"-events, which gets triggert, can be difference.
        This value is for getting the Y-Distance between "scroll"-event triggers
      */
    let lastElBottom = ref.current.getBoundingClientRect().bottom

    ref.current.style.position = 'relative'
    ref.current.style.transition = 'transform 0.2s ease-out'

    window.addEventListener(
      'scroll',
      () => {
        if (!ref?.current) return
        const currElBottom = ref.current.getBoundingClientRect().bottom
        const diffElY = Math.abs(lastElBottom - currElBottom)
        // slow down element
        if (
          isDownScroll.current &&
          currElBottom < animTriggerPoint &&
          currElBottom > 0
        ) {
          currTrans = Number(Number(currTrans + 0.1 * diffElY).toFixed(4))
          ref.current.style.transform = `translateY(${currTrans}px)`
        }
        // speed up element on scroll back
        if (!isDownScroll.current && currElBottom > 0 && currTrans !== 0) {
          currTrans = Number(Number(currTrans - 0.2 * diffElY).toFixed(4))
          if (currTrans < 0) currTrans = 0
          ref.current.style.transform = `translateY(${currTrans}px)`
        }

        lastElBottom = currElBottom
      },
      true
    )
  }, [])
}
