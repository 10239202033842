import React, { ElementType, ReactElement } from 'react'

import Typography from '@mui/material/Typography'

export type CopyProps = LAW.Props.IDefault & {
  level?: number
  id?: string
  html?: string
  variant?: 'body1' | 'body2'
  component?: ElementType<any>
}

export default function Copy({
  children,
  id,
  html,
  variant = 'body1',
  component,
  sx,
}: CopyProps): ReactElement {
  const additionalProps = {
    ...(html && {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    }),
    ...(component && {
      component,
    }),
  }
  return (
    <Typography
      id={id}
      variant={variant}
      {...additionalProps}
      sx={sx}
      gutterBottom={false}
    >
      {children}
    </Typography>
  )
}
