import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'

export type ImageProps = LAW.Props.IDefault & {
  data: IGatsbyImageData
  alt?: string
  eager?: boolean
  objectFit?: 'contain' | 'fill' | 'none' | 'cover' | 'scale-down' | undefined
  objectPosition?: string | undefined
  position?: 'static' | 'relative'
  backgroundColor?: 'string'
  sectionBackground?: IGatsbyImageData
  sectionBackgroundMobile?: IGatsbyImageData
}

/**
 * Image
 */
export default function Image({
  data,
  sectionBackground,
  sectionBackgroundMobile,
  alt = '',
  eager,
  position = 'relative',
  objectFit,
  objectPosition,
  backgroundColor,
  sx = [],
}: ImageProps): ReactElement {
  const images =
    !!sectionBackgroundMobile &&
    withArtDirection(getImage(sectionBackground?.data), [
      {
        media: '(max-width: 1024px)',
        image: getImage(sectionBackgroundMobile?.data),
      },
    ])

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <GatsbyImage
        image={!!sectionBackgroundMobile && images ? images : data}
        alt={alt}
        loading={eager ? 'eager' : 'lazy'}
        style={{ position }}
        objectFit={objectFit}
        objectPosition={objectPosition}
        backgroundColor={backgroundColor}
      />
    </Box>
  )
}
