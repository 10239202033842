import React, { useContext } from 'react'
import { Box, Theme, styled, Typography, Grid } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'

import useInViewAnim from '@components/system/hooks/useInViewAnim'
import ThemeContext from '@providers/themeProvider'

import Image from '@components/core/media/image'
import Section from '@components/core/container/section'
import FontSize from '@config/theme/definitions/fontSize'
import TextBlock from '@components/core/text/block'

export type TeaserCard = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    caption: string
    headline: string
    image: LAW.Props.Image
    linkTo: LAW.Props.LinkTo
  }

export type TeaserRelatedProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    items: Array<TeaserCard>
  }

export default function TeaserRelated({
  headline,
  items,
}: TeaserRelatedProps): React.ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)
  const [imageInViewRef, imageInViewSX] = useInViewAnim('bottom-to-top')
  function renderTeaserCards(items: Array<TeaserCard>) {
    const SxLink = styled(GatsbyLink)({})

    return items?.map((item, i) => {
      return (
        <Grid item xs={12} md={6} key={`teaser-item-${i}`}>
          <SxLink
            to={item?.linkTo?.url}
            sx={{
              textDecoration: 'none',
              '& img': {
                height: '100%',
                width: '100%',
                transition: 'all 0.3s ease-in-out !important',
              },
              '& .gatsby-image-wrapper': {
                overflow: 'hidden',
                willChange: 'transform', // safari fix
                borderRadius: '8px', // safari fix
              },
              '&:hover': {
                '& img': {
                  transform: 'scale(1.05)',
                },
              },
            }}
          >
            <Image
              {...item.image}
              sx={(muiTheme: Theme) => ({
                borderRadius: '8px',
                overflow: 'hidden',
                '& img': {
                  borderRadius: '8px',
                },
                [muiTheme.breakpoints.up('md')]: {
                  '& img': {
                    borderRadius: '8px',
                  },
                },
              })}
            />

            <Box
              sx={(muiTheme: Theme) => ({
                mt: 4,
                [muiTheme.breakpoints.up('md')]: {
                  fontSize: FontSize.get('lg'),
                  mt: 6,
                },
              })}
            >
              <Typography
                variant="caption"
                sx={(muiTheme: Theme) => ({
                  mb: 2,
                  color:
                    pageTheme === 'sustainability'
                      ? muiTheme.palette.sustainability.main
                      : 'primary.main',
                })}
              >
                {item.caption}
              </Typography>

              {!!item.headline && (
                <Typography variant="h3">{item.headline}</Typography>
              )}
            </Box>
          </SxLink>
        </Grid>
      )
    })
  }

  return (
    <Section
      roundedBorders
      withShadow
      sx={{
        py: { xs: 20, md: 30 },
      }}
      maxWidth="xl"
    >
      <TextBlock headline={headline} />
      <Grid ref={imageInViewRef} container spacing={8} sx={imageInViewSX}>
        {renderTeaserCards(items)}
      </Grid>
    </Section>
  )
}
