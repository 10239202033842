import Copy from '@components/core/text/copy'
import Icon from '@components/core/ui/icon'
import { Box, Theme } from '@mui/material'
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
  useContext,
} from 'react'

import ThemeContext from '@providers/themeProvider'

import YouTubePlayer from 'react-youtube'
import { YouTubePlayer as YoutubePlayerTypes } from 'youtube-player/dist/types'
import Image from '../image'

export type YouTubeProps = LAW.Props.IDefault & {
  youtubeId?: string
  copyright?: string
  description?: string
  thumbnail?: LAW.Props.Image
  alt?: string
  thumbnailOnly?: boolean
  onThumbnailClick?: () => void
}

const YouTube = forwardRef(function YouTubeInner(
  {
    youtubeId,
    copyright,
    description,
    thumbnail,
    alt,
    thumbnailOnly,
    onThumbnailClick,
    sx = {},
  }: YouTubeProps,
  ref
): ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)
  const ytRef = useRef(null)
  const [init, setInit] = useState(true)
  const [showiFrame, setShowiFrame] = useState(false)
  const [player, setPlayer] = useState<YoutubePlayerTypes | null>(null)

  useEffect(() => {
    if (!init) {
      player?.playVideo()
    }
  }, [youtubeId, init])

  function initVideo() {
    if (typeof onThumbnailClick === 'function') {
      onThumbnailClick()
    }
    if (!player || thumbnailOnly) return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setShowiFrame(true)
    setInit(false)
  }

  useImperativeHandle(ref, () => ({
    playVideo() {
      initVideo()
      setInit(false)
    },
  }))

  console.log('log youtube thumbnail', `https:${thumbnail?.file.url}`)
  console.log('log youtube has thumbnail', !!thumbnail)
  console.log('log youtube thumbnailOnly', thumbnailOnly)
  console.log('log youtube showiFrame', showiFrame)

  const ytThumbnail = thumbnail?.file.url && `https:${thumbnail?.file.url}`
  console.log('log youtube ytThumbnail', ytThumbnail)

  return (
    <Box
      sx={[
        (muiTheme: Theme) => ({
          '& .uc-embedding-container': {
            position: 'absolute',
            zIndex: 100,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            '& .uc-embedding-wrapper': {
              padding: muiTheme.spacing(4),
              borderRadius: 0,
              '& .uc-embedding-more-info': {
                borderRadius: 0,
              },
              '& .uc-embedding-accept': {
                borderRadius: 0,
              },
            },
            '& img': {
              display: 'none',
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-testid={'youtube-video'}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: 0,
          borderRadius: '8px',
          willChange: 'transform', // safari fix
          paddingTop: 'calc(9 / 16 * 100%)',
          backgroundColor: 'background.primary',
          overflow: 'hidden',
          '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        }}
      >
        {!thumbnailOnly && (
          <YouTubePlayer
            ref={ytRef}
            videoId={youtubeId}
            opts={{
              host: 'https://www.youtube-nocookie.com',
              playerVars: {
                rel: 0,
                controls: 0,
                showinfo: 0,
              },
            }}
            onReady={({ target }) => {
              setPlayer(target)
            }}
          />
        )}
        {!showiFrame && (
          <Box
            sx={(theme) => ({
              position: 'absolute',

              width: '100%',
              height: '100%',
              top: '0%',
              left: '0%',
              transition: 'all .3s !important',
              [theme.breakpoints.up('lg')]: {
                '&:hover': {
                  height: '120% !important',
                  width: '120% !important',
                  top: '-10% !important',
                  left: '-10% !important',
                  transition: 'all .3s',
                },
              },
            })}
            onClick={initVideo}
          >
            {!!ytThumbnail ? (
              <Image
                data={{
                  images: {
                    sources: [
                      {
                        srcSet: ytThumbnail,
                        sizes: '',
                        type: '',
                      },
                    ],
                    fallback: {
                      src: `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`,
                    },
                  },
                  layout: 'fullWidth',
                  width: 16,
                  height: 9,
                }}
                alt={alt || `Youtube Video ${youtubeId}`}
              />
            ) : (
              <img
                width="100%"
                height="auto"
                src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`}
              />
            )}
            <Box
              className="playpause"
              sx={(theme) => ({
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                top: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                opacity: 1,
                transition: 'opacity 400ms',
                [theme.breakpoints.up('lg')]: {
                  '&:hover': {
                    cursor: 'pointer',
                  },
                },
              })}
            >
              <Icon
                name="PlayButton"
                fontSize="inherit"
                sx={{
                  color:
                    pageTheme === 'sustainability'
                      ? 'sustainability.main'
                      : 'primary.main',
                  fontSize: '64px',
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      {(description || copyright) && (
        <div>
          {description && <Copy>{description}</Copy>}
          {copyright && <Copy>{copyright}</Copy>}
        </div>
      )}
    </Box>
  )
})

export default YouTube
