import { getImage } from 'gatsby-plugin-image'
import renderRichText from '@utils/richtext'
import { ReactNode } from 'react'

export const getAssetProp = (
  value: LAW.Props.Video | LAW.Props.Image
): LAW.Props.Image | LAW.Props.Svg | LAW.Props.Video => {
  const isImage = value?.file?.contentType.startsWith('image')
  const asset = {
    title: value?.title,
    description: value?.description,
    file: value?.file,
  }
  if (isImage && (value as LAW.Contentful.IAssetSvg).svg) {
    return {
      ...asset,
      content: (value as LAW.Contentful.IAssetSvg).svg.content,
    }
  }

  return isImage && (value as LAW.Contentful.IAssetImage).gatsbyImageData
    ? {
        ...asset,
        data: getImage((value as LAW.Contentful.IAssetImage).gatsbyImageData),
      }
    : asset
}

export const getRichTextProp = (
  value: LAW.Contentful.BasicRichTextType
): ReactNode => {
  return value ? renderRichText(value) : null
}
