import React, { ReactElement } from 'react'

import PageModuleParser from './PageModuleParser'

export type PageModuleProps = LAW.Props.IDefault & {
  pagemodule: LAW.Contentful.ModuleUnion & { __typename: string }
}

export default function PageModule({
  pagemodule,
}: PageModuleProps): ReactElement {
  const Parser = PageModuleParser.find((parsermodule) => {
    return pagemodule.__typename === parsermodule.__typename
  })

  if (!Parser) {
    console.log('Module not defined: ', pagemodule)
    return <></>
  }

  return React.createElement(Parser.component, Parser.props(pagemodule))
}
