import React from 'react'

import Section from '@components/core/container/section'
import YouTube from '@components/core/media/youtube'
import { Theme, Box, styled, Typography, SvgIcon } from '@mui/material'

import VideoHighlightIllu from '@static/img/VideoHighlightIllu.svg'

export type VideoHighlightProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    description?: string
    youtubeId?: string
    thumbnail?: { file: { url: string } }
    illustration?: { file: { url: string } }
  }

export default function VideoHighlight({
  caption,
  headline,
  description,
  youtubeId,
  thumbnail,
  illustration,
}: VideoHighlightProps): React.ReactElement {
  const isVideoOnly = !caption && !headline

  const SxSvg = styled('img')({})

  console.log('log videoHighlight thumbnail', thumbnail)

  return (
    <Section
      theme="dark"
      roundedBorders
      withShadow
      sx={(muiTheme: Theme) => ({
        overflow: 'hidden',
        pt: 20,
        pb: 28,
        [muiTheme.breakpoints.up('md')]: {
          pt: 30,
          pb: 40,
        },
      })}
      maxWidth="xl"
    >
      <Box sx={{}}>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: { lg: 'none' },
              position: 'absolute',
              right: 0,
              top: 0,
              transform: 'translateX(50%)',
            }}
          >
            {!!illustration ? (
              <SxSvg
                src={illustration?.file?.url}
                sx={{
                  width: 'auto',
                  maxHeight: 320,
                }}
              />
            ) : (
              <SvgIcon
                component={VideoHighlightIllu}
                viewBox="0 0 381 500"
                sx={{
                  maxHeight: '490px',
                  width: { xs: '100%', md: 'auto' },
                  height: { xs: '320px', md: '490px' },
                }}
              />
            )}
          </Box>
          <Typography
            variant="h2"
            sx={{
              position: 'relative',
              maxWidth: '768px',
            }}
          >
            {headline}
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', lg: 'flex' },
              position: 'absolute',
              left: 0,
              marginTop: '45px',
            }}
          >
            {!!illustration ? (
              <SxSvg
                src={illustration?.file?.url}
                sx={{
                  width: 'auto',
                  maxHeight: 490,
                }}
              />
            ) : (
              <SvgIcon
                component={VideoHighlightIllu}
                viewBox="0 0 381 500"
                sx={{
                  maxHeight: '490px',
                  width: { xs: '100%', md: 'auto' },
                  height: { xs: '320px', md: '490px' },
                }}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={(muiTheme: Theme) => ({
            mt: 20,
            position: 'relative',
            [muiTheme.breakpoints.up('lg')]: {
              mt: 30,
              ml: 75,
            },
          })}
        >
          <YouTube
            youtubeId={youtubeId}
            thumbnail={thumbnail}
            sx={(muiTheme: Theme) => ({
              mt: !isVideoOnly ? 10 : undefined,
              [muiTheme.breakpoints.up('xl')]: {
                mt: !isVideoOnly ? 20 : undefined,
                maxWidth: '88%',
              },
            })}
          />
          {description && (
            <Typography
              variant="h4"
              sx={{
                mt: { xs: 4, lg: 8 },
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
      </Box>
    </Section>
  )
}
