import React from 'react'
import { Grid } from '@mui/material'

import Section from '@components/core/container/section'
import TextBlock from '@components/core/text/block'
import ManagementCard, { ManagementCardProps } from './card'

export type ManagementProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    items: Array<ManagementCardProps>
  }

export default function Management({
  headline,
  items,
}: ManagementProps): React.ReactElement {
  function renderManagementCards(items: Array<ManagementCardProps>) {
    return items?.map((item) => {
      if (!item.image || !item.jobTitle || !item.name) return

      return <ManagementCard key={item.id} {...item} />
    })
  }

  return (
    <Section
      roundedBorders
      withShadow
      sx={{
        py: { xs: 20, md: 30 },
        backgroundColor: '#FFFFFF',
      }}
      maxWidth="xl"
    >
      <TextBlock headline={headline} />
      <Grid container spacing={{ xs: 4, md: 8 }}>
        {renderManagementCards(items)}
      </Grid>
    </Section>
  )
}
