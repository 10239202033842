import React, { useContext } from 'react'

import { Box, Theme, Typography } from '@mui/material'

import renderRichText from '@utils/richtext'
import ThemeContext from '@providers/themeProvider'

export interface IHistoryItem {
  year: number
  tagline: string
  headline?: string
  itemCopy?: LAW.Contentful.BasicRichTextType
}

export default function History({
  tagline,
  headline,
  itemCopy,
}: IHistoryItem): React.ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)

  return (
    <Box
      sx={{
        mb: '80px',
      }}
    >
      <Box
        sx={{
          mt: 2,
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="caption"
          sx={(muiTheme: Theme) => ({
            color:
              pageTheme === 'sustainability'
                ? muiTheme.palette.sustainability.main
                : 'primary.main',
          })}
        >
          {tagline}
        </Typography>
        {headline && (
          <Typography
            variant="h3"
            sx={{
              pt: { xs: 2, md: 4 },
            }}
          >
            {headline}
          </Typography>
        )}
        {itemCopy && (
          <Box
            sx={(muiTheme: Theme) => ({
              pt: 4,
              [muiTheme.breakpoints.up('md')]: {
                pt: 6,
              },
            })}
          >
            {renderRichText(itemCopy)}
          </Box>
        )}
      </Box>
    </Box>
  )
}
