import React, { useState, useContext } from 'react'
import Swiper from 'swiper'

import { Box, useMediaQuery, Theme, useTheme, Container } from '@mui/material'

import useInViewAnim from '@components/system/hooks/useInViewAnim'

import Button from '@components/core/ui/button'
import Section from '@components/core/container/section'
import Carousel from '@components/core/ui/carousel'
import TeaserCarouselItem from '@components/modules/teaserCarousel/item'
import TeaserCarouselTextItem from '@components/modules/teaserCarousel/item/textItem'
import TextBlock from '@components/core/text/block'

import ThemeContext from '@providers/themeProvider'

export type CarouselCard = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    caption?: string
    jobTitle?: string
    headline: string
    media?: LAW.Props.Video | LAW.Props.Image
    poster?: LAW.Props.Image
    linkTo?: LAW.Props.LinkTo
    type: string
    copy: string
  }

export type TeaserCarouselProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    caption?: string
    headline: string
    copy?: LAW.Contentful.BasicRichTextType
    linkTo?: LAW.Props.LinkTo
    callToAction?: string
    items: Array<CarouselCard>
    type?: string
  }

export default function TeaserCarousel({
  caption,
  headline,
  copy,
  linkTo,
  callToAction,
  items,
  type,
}: TeaserCarouselProps): React.ReactElement {
  const theme = useTheme()
  const [contentInViewRef, contentInViewSX] = useInViewAnim('right-to-left')

  useState<CarouselCard | null>(items?.length ? items[0] : null)

  const { theme: pageTheme } = useContext(ThemeContext)

  const [isBeginning, setIsBeginning] = useState<boolean>(true)
  const [isEnd, setIsEnd] = useState<boolean>(false)
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const showNavigationButtons = isLarge && items && items.length > 3
  const [swiper, setSwiper] = useState<Swiper>()

  function onSwiper(swiper: Swiper): void {
    setSwiper(swiper)
  }

  function renderCarouselCards(items: Array<CarouselCard>) {
    return items?.map((item, i) => {
      const withVideo =
        !!item.media?.file && item.media?.file.contentType.startsWith('video')

      switch (type) {
        case 'text':
          return (
            //Den switch baue ich im CMS in Module-TeaserCarouseText als Radio Button ein "text | facts"
            <TeaserCarouselTextItem
              type="text"
              headline={item.headline}
              copy={item.copy}
              key={`carousel-item-${i}`}
              linkTo={item.linkTo}
            />
          )
        case 'facts':
          return (
            <TeaserCarouselTextItem
              key={`carousel-item-${i}`}
              type="facts"
              caption={item.headline}
              copy={item.copy}
            />
          )
        case 'media':
          return (
            <TeaserCarouselItem
              media={item.media}
              poster={item.poster}
              caption={item.caption}
              jobTitle={item.jobTitle}
              withVideo={withVideo}
              key={`carousel-item-${i}`}
              itemIndex={`carousel-item-${i}`}
              linkTo={withVideo ? undefined : linkTo}
            />
          )
        default:
          return (
            <TeaserCarouselItem
              media={item.media}
              poster={item?.poster}
              caption={item.caption}
              jobTitle={item.jobTitle}
              withVideo={withVideo}
              key={`carousel-item-${i}`}
              itemIndex={`carousel-item-${i}`}
              linkTo={linkTo}
            />
          )
      }
    })
  }

  return (
    <React.Fragment>
      <Section
        roundedBorders
        withShadow
        sx={{
          py: { xs: 20, md: 30 },
          backgroundColor: '#FFFFFF',
        }}
        maxWidth={false}
      >
        <Container maxWidth="xl">
          <TextBlock
            caption={caption}
            headline={headline}
            copy={copy}
            linkTo={linkTo}
            callToAction={callToAction}
          />
        </Container>
        {!!items?.length && (
          <Box
            sx={{
              display: 'flex',
              pl: { xs: 0, xl: 34, xxl: 'calc((100% - 1920px)/2 + 136px)' },
            }}
          >
            {showNavigationButtons && (
              <Box
                sx={{
                  zIndex: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '1em',
                  px: 34,
                }}
              >
                <Button
                  variant="round"
                  icon="ArrowRight"
                  disabled={isEnd}
                  onClick={() => swiper?.slideNext()}
                  sx={(muiTheme: Theme) => ({
                    zIndex: 1,
                    ...(pageTheme === 'sustainability' && {
                      backgroundColor: muiTheme.palette.sustainability.main,
                    }),
                  })}
                />

                <Button
                  variant="round"
                  icon="ArrowLeft"
                  disabled={isBeginning}
                  onClick={() => swiper?.slidePrev()}
                  sx={(muiTheme: Theme) => ({
                    zIndex: 1,
                    ...(pageTheme === 'sustainability' && {
                      backgroundColor: muiTheme.palette.sustainability.main,
                    }),
                  })}
                />
              </Box>
            )}
            <Box
              ref={contentInViewRef}
              sx={{
                ...contentInViewSX,
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                overflow: 'hidden',
                pb: 0.5,
                '& .swiper-slide': {
                  width: { sm: 368 },
                },
              }}
            >
              <Carousel
                hasPagination={false}
                onSwiper={onSwiper}
                onActiveIndexChange={({
                  isBeginning,
                  isEnd,
                }: {
                  isBeginning: boolean
                  isEnd: boolean
                }) => {
                  setIsBeginning(isBeginning)
                  setIsEnd(isEnd)
                }}
                slidesPerView={1.2}
                spaceBetween={16}
                slidesOffsetBefore={24}
                slidesOffsetAfter={24}
                breakpoints={{
                  640: {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 24,
                    slidesOffsetAfter: 24,
                    spaceBetween: 16,
                  },
                  768: {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 32,
                    slidesOffsetAfter: 32,
                    spaceBetween: 24,
                  },
                  1024: {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 0,
                    slidesOffsetAfter: 32,
                    spaceBetween: 32,
                  },
                }}
              >
                {renderCarouselCards(items)}
              </Carousel>
            </Box>
          </Box>
        )}
      </Section>
    </React.Fragment>
  )
}
