/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { renderRichText as origRenderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES, Text } from '@contentful/rich-text-types'
import parse from 'html-react-parser'
import { Theme, Typography } from '@mui/material'
import Copy from '@components/core/text/copy'
import Quote from '@components/core/text/quote'
import Link from '@components/core/ui/link'
import List from '@components/core/text/list'
import Image from '@components/core/media/image'
import PageModule from '@components/system/PageModule'

export default function renderRichText(
  richText: LAW.Contentful.BasicRichTextType,
  simple?: boolean,
  withQuoteOffset?: boolean,
  withChecklist?: boolean
) {
  return origRenderRichText(richText, {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    },
    renderText: (text: string) => {
      return parse(
        text
          .replaceAll('\n', '<br>')
          .replaceAll('®', '<sup>®</sup>')
          .replaceAll('©', '<sup>©</sup>')
          .replaceAll('℗', '<sup>℗</sup>')
      )
    },
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const sxProp = (muiTheme: Theme) => ({
          mb: 12,
          [muiTheme.breakpoints.up('md')]: {
            mb: 20,
          },
        })

        if (simple) {
          return children
        } else if (
          node.content.length === 1 &&
          (node.content[0] as Text).value === ''
        ) {
          // remove empty paragraphs
          return <></>
        } else {
          return (
            <Copy variant="body2" sx={sxProp}>
              {children}
            </Copy>
          )
        }
      },
      [BLOCKS.QUOTE]: (node: any) => {
        const sxProp = (muiTheme: Theme) => ({
          mb: 12,
          [muiTheme.breakpoints.up('md')]: {
            mb: 20,
          },
          '&:first-of-type': {
            mt: 10,
            [muiTheme.breakpoints.up('md')]: {
              mt: 12,
            },
          },
        })

        return (
          <Quote
            author={node.content[1]?.content?.[0].value}
            position={node.content[2]?.content?.[0].value}
            sx={sxProp}
            offset={withQuoteOffset}
          >
            {node.content[0]?.content?.[0].value}
          </Quote>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <Typography variant="h1">{children}</Typography>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <Typography variant="h2">{children}</Typography>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <Typography variant="h3" sx={{ mb: { xs: 6, xl: 8 } }}>
            {children}
          </Typography>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <Typography variant="h4" sx={{ mb: { xs: 2, xl: 4 } }}>
            {children}
          </Typography>
        )
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <Typography variant="h5">{children}</Typography>
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <List type="unordered" style={withChecklist ? 'checkmark' : 'bullet'}>
            {children}
          </List>
        )
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <List type="ordered">{children}</List>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => children,
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target) {
          return (
            <PageModule
              key={`${node.data.target.id}`}
              pagemodule={node.data.target}
            />
          )
        }
        console.log('Missing embedded entry', node)
        return <></>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (!node.data.target) return
        return (
          <Image
            data={node.data.target.gatsbyImageData}
            alt={node.data.target.description}
          />
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Link to={node.data.uri} isExternal>
            {children}
          </Link>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const link = node.data?.target?.fields?.fullPath
        return link ? <Link to={link}>{children}</Link> : children
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return children
      },
    },
  })
}
