import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import useInViewAnim from '@components/system/hooks/useInViewAnim'
import Image from '@components/core/media/image'

export type ManagementCardProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    jobTitle?: string
    name: string
    image: LAW.Props.Image
  }

export default function ManagementCard({
  jobTitle,
  name,
  image,
}: ManagementCardProps) {
  const [imagesInViewRef, imagesInViewSx] = useInViewAnim('bottom-to-top', {
    delay: 0.1,
  })
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        mb: 4,
      }}
    >
      <Box ref={imagesInViewRef} sx={imagesInViewSx}>
        <Image
          {...image}
          sx={{
            '& img': {
              borderRadius: '8px',
            },
          }}
        />

        <Box
          sx={{
            mt: { xs: 4, md: 6 },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: 2,
            }}
          >
            {name}
          </Typography>
          <Typography variant="body1">{jobTitle}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}
