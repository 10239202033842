import React from 'react'
import { Box, Theme, Grid, useMediaQuery, useTheme } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Section from '@components/core/container/section'
import Carousel from '@components/core/ui/carousel'
import TeaserContentItem from '@components/modules/teaserContent/item'
import TextBlock from '@components/core/text/block'

export type TeaserContentProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    caption?: string
    headline: string
    copy: LAW.Contentful.BasicRichTextType
    jobs?: LAW.Props.IGlobalJob[]
    departments?: LAW.Props.IPageDepartment[]
    items?: LAW.Contentful.ITeaserContentItem[]
    linkTo?: LAW.Props.LinkTo
    callToAction?: string
  }

export default function TeaserContent({
  caption,
  headline,
  copy,
  jobs,
  departments,
  items,
  linkTo,
  callToAction,
}: TeaserContentProps): React.ReactElement {
  const theme = useTheme()

  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <Section
      theme={!!items ? 'red' : undefined}
      roundedBorders
      withShadow
      sx={{
        py: { xs: 20, md: 30 },
      }}
      maxWidth="xl"
    >
      <TextBlock
        theme={!!items ? 'red' : undefined}
        caption={caption}
        headline={headline}
        copy={copy}
        linkTo={linkTo}
        callToAction={callToAction}
      />

      {jobs && (
        <Box
          sx={{
            overflow: 'hidden',
            mx: { xs: -6, md: -8, lg: 0 },
          }}
        >
          <Carousel
            hasPagination={false}
            slidesPerView={1.2}
            spaceBetween={16}
            allowTouchMove
            slidesOffsetBefore={24}
            slidesOffsetAfter={24}
            breakpoints={{
              640: {
                slidesPerView: 1.8,
                slidesOffsetBefore: 24,
                slidesOffsetAfter: 24,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 24,
                allowTouchMove: true,
                slidesOffsetBefore: 32,
                slidesOffsetAfter: 32,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 24,
                allowTouchMove: false,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 32,
                allowTouchMove: false,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
            }}
          >
            {jobs.map((entry, index) => (
              <TeaserContentItem
                key={index}
                caption={entry.department}
                headline={entry.title}
                copy={entry.copy}
                linkTo={{
                  ...entry.linkTo,
                  label: <FormattedMessage id="button.readFurther" />,
                }}
                sx={{
                  height: '100%',
                  boxSizing: 'border-box',
                }}
                animationType={isLarge ? 'bottom-to-top' : 'right-to-left'}
                delay={isLarge ? 0.3 * (index % 3) : 0}
              />
            ))}
          </Carousel>
        </Box>
      )}
      {departments && (
        <Grid container spacing={8}>
          {departments.map((entry, index) => (
            <Grid
              item
              key={index}
              xs={12}
              md={6}
              lg={4}
              sx={(muiTheme: Theme) => ({
                [muiTheme.breakpoints.up('md')]: {
                  mb: 4,
                },
              })}
            >
              <TeaserContentItem
                headline={entry.teaserTitle || ''}
                copy={entry.teaserDescription || ''}
                linkTo={{
                  url: entry.fields.linkTo.url,
                  label: <FormattedMessage id="button.readFurther" />,
                }}
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                delay={
                  isLarge ? 0.3 * (index % 3) : isMedium ? 0.3 * (index % 2) : 0
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
      {items && (
        <Grid container spacing={8}>
          {items.map((entry, index) => (
            <Grid
              item
              key={index}
              xs={12}
              md={6}
              lg={items.length < 3 ? 6 : 4}
              sx={(muiTheme: Theme) => ({
                [muiTheme.breakpoints.up('md')]: {
                  mb: 4,
                },
              })}
            >
              <TeaserContentItem
                theme="red"
                headline={entry.headline || ''}
                richtext={entry.bulletlist || ''}
                listType={entry.listType}
                icon={entry.icon}
                linkTo={
                  entry.linkPage
                    ? {
                        url: entry.linkPage?.fields.linkTo.url,
                        label: <FormattedMessage id="button.readFurther" />,
                      }
                    : undefined
                }
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                delay={
                  isLarge ? 0.3 * (index % 3) : isMedium ? 0.3 * (index % 2) : 0
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Section>
  )
}
