import React, { Fragment, ReactElement } from 'react'

import { styled, Box } from '@mui/material'

import Icon from '@components/core/ui/icon'

export type ListProps = LAW.Props.IDefault & {
  type?: 'ordered' | 'unordered'
  style?: 'bullet' | 'checkmark'
}

const UnorderedStyled = styled('ul')({
  listStyleType: 'none',
  paddingLeft: 16,
})

const CheckmarkStyled = styled('ul')({
  listStyleType: 'none',
  paddingLeft: 16,
})

const OrderedStyled = styled('ol')({
  paddingLeft: '1em',
})

export default function List({
  type = 'unordered',
  style = 'bullet',
  children,
  sx,
}: ListProps): ReactElement {
  const Component =
    type === 'ordered'
      ? OrderedStyled
      : style === 'checkmark'
      ? CheckmarkStyled
      : UnorderedStyled
  return (
    <Component sx={sx}>
      {React.Children.map(children, (child, index) => {
        return child ? (
          <Box
            component="li"
            key={index}
            sx={{
              marginBottom: 2,
              paddingLeft: 2,
              position: 'relative',
              '&:last-child': { mb: 0 },
              '&::marker': {
                fontSize: '20px',
                lineHeight: 1,
              },
            }}
          >
            {style === 'checkmark' ? (
              <Icon
                name="Check"
                fontSize="small"
                sx={{
                  position: 'absolute',
                  left: -16,
                  top: 4,
                }}
              />
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  left: -16,
                  top: 8,
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  background: '#fff',
                }}
              ></Box>
            )}
            {(child as React.ReactElement)?.props?.children}
          </Box>
        ) : (
          <Fragment key={index} />
        )
      })}
    </Component>
  )
}
