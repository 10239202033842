import React from 'react'
import Video, { VideoProps } from '../video'
import YouTubePlayer, { YouTubeProps } from '../youtube'

export type VideoYouTubeSwitchProps = VideoProps &
  YouTubeProps & {
    video: LAW.Contentful.IAsset
    poster: LAW.Contentful.IAssetImage
    thumbnail?: { file: { url: string } }
  }

export default function VideoYouTubeSwitch(
  props: VideoYouTubeSwitchProps
): React.ReactElement {
  if (props.youtubeId) {
    return <YouTubePlayer {...props} />
  }

  return (
    <Video
      src={props.video.file.url}
      poster={props.poster.file.url}
      videoItemIndex={props.video.contentful_id}
    />
  )
}
