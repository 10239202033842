import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import useLangSwitch from '@components/system/hooks/useLangSwitch'
import Section from '@components/core/container/section'
import { Box } from '@mui/material'

export type JobportalProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    caption?: string
    headline?: string
    description?: string
  }

export default function Jobportal({}: JobportalProps): React.ReactElement {
  const [widgetScriptready, setWidgetScriptready] = useState(false)
  const { langSwitchLink } = useLangSwitch()

  useEffect(() => {
    const widgetscriptInterval = setInterval(() => {
      if (!!window.widget && !!window.jQuery) {
        setWidgetScriptready(true)

        window.widget(
          {
            company_code: 'LampenweltGmbH',
            bg_color_widget: '#ffffff',
            bg_color_headers: '#1b1e20',
            bg_color_links: '#d7dbdd',
            txt_color_headers: '#ffffff',
            txt_color_job: '#1b1e20',
            bg_color_even_row: '#f4f4f5',
            bg_color_odd_row: '#ffffff',
            auto_width: 'auto',
            auto_height: 'auto',
            number: 'on',
            job_title: 'true',
            location: 'true',
            department: 'true',
            dateFormat: 'us',
            add_search: 'true',
            filter_locations: '',
            departments_field_id: '6169ec6f50203b6558a4be92',
            jobAdType: 'PUBLIC',
            trid: '',
            api_url: 'https://www.smartrecruiters.com',
            custom_css_url:
              'https://static.smartrecruiters.com/job-widget/1.6.1/css/smart_widget.css',
          },
          `job_widget`
        )
      }
    }, 400)
    if (widgetScriptready) {
      clearInterval(widgetscriptInterval)
    }
    return () => {
      clearInterval(widgetscriptInterval)
    }
  }, [widgetScriptready])

  // necessary on language switch on the same page, the iframe needs to be reloaded, otherwise it will not be displayed anymore (don't know why)
  useEffect(() => {
    setWidgetScriptready(false)
  }, [langSwitchLink])

  return (
    <Section
      roundedBorders
      withShadow
      sx={{
        pt: { xs: 16, md: 20 },
        pb: { xs: 25, md: 30 },
      }}
      maxWidth="xl"
    >
      <Helmet>
        <script
          type="text/javascript"
          id="jobportal_widget_script"
          src="https://static.smartrecruiters.com/job-widget/1.6.1/script/smart_widget.js"
        ></script>
      </Helmet>
      <Box>
        <div
          key={`job_widget${langSwitchLink}`}
          className={`smartWidget`}
          id="job_widget"
        ></div>
      </Box>
    </Section>
  )
}
