import React from 'react'
import { Box, Theme, Typography } from '@mui/material'

import renderRichText from '@utils/richtext'
import useInViewAnim from '@components/system/hooks/useInViewAnim'
import theme from '@config/theme'

import Section from '@components/core/container/section'
import IconList from '@components/core/ui/iconList'

export type IntroProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    headline: string
    introsubline?: LAW.Contentful.BasicRichTextType
    content?: LAW.Contentful.BasicRichTextType
    checklistTitle?: string
    checklist?: string
    techStackTitle?: string
    techStack?: string
  }

export default function Intro({
  headline,
  introsubline,
  content,
  checklistTitle,
  checklist,
  techStackTitle,
  techStack,
}: IntroProps): React.ReactElement {
  const [headlineInViewRef, headlineInViewSX] = useInViewAnim('bottom-to-top')
  const [sublineInViewRef, sublineInViewSx] = useInViewAnim('bottom-to-top', {
    delay: 0.2,
  })

  return (
    <React.Fragment>
      <Section
        roundedBorders
        withShadow
        sx={(muiTheme: Theme) => ({
          pt: 20,
          pb: techStack ? 0 : 20,
          [muiTheme.breakpoints.up('md')]: {
            pt: 30,
            pb: techStack ? 0 : 30,
          },
        })}
        maxWidth="xl"
      >
        <Typography
          ref={headlineInViewRef}
          variant="h2"
          sx={(muiTheme: Theme) => ({
            ...headlineInViewSX,
            [muiTheme.breakpoints.up('md')]: {
              maxWidth: 768,
            },
          })}
        >
          {headline}
        </Typography>
        {(introsubline || content) && (
          <Box
            sx={(muiTheme: Theme) => ({
              display: 'flex',
              position: 'relative',
              mt: 12,
              [muiTheme.breakpoints.up('md')]: {
                mt: 20,
              },
            })}
          >
            {
              <Box
                sx={(muiTheme: Theme) => ({
                  [muiTheme.breakpoints.up('md')]: {
                    display: !checklist ? 'none' : 'block',
                    width: { md: 300, lg: 332, xl: 400 },
                    flex: { md: '0 0 300px', lg: '0 0 332px', xl: '0 0 400px' },
                    height: '100%',
                  },
                  [muiTheme.breakpoints.up('lg')]: {
                    display: 'block',
                  },
                })}
              />
            }
            <Box>
              {introsubline && (
                <Typography
                  ref={sublineInViewRef}
                  variant="body1"
                  sx={(muiTheme: Theme) => ({
                    ...sublineInViewSx,
                    fontWeight: 600,
                    mb: !!content ? 12 : 0,
                    [muiTheme.breakpoints.up('md')]: {
                      mb: !!content ? 20 : 0,
                    },
                  })}
                >
                  {renderRichText(introsubline, true)}
                </Typography>
              )}
              {content && (
                <>
                  {renderRichText(content, false, !checklist)}

                  {checklist && (
                    <Box
                      sx={(muiTheme: Theme) => ({
                        [muiTheme.breakpoints.up('md')]: {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: 268,
                        },
                      })}
                    >
                      <IconList
                        sx={(muiTheme: Theme) => ({
                          mx: 'auto',
                          [muiTheme.breakpoints.up('md')]: {
                            mx: 0,
                          },
                        })}
                        headline={checklistTitle}
                        items={checklist?.split('\n')}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        )}
      </Section>
      {techStack && (
        <Section
          theme="light"
          roundedBorders
          sx={{
            pt: { xs: 16, md: 20 },
            pb: { xs: 28, md: 30 },
          }}
          maxWidth="xl"
        >
          {techStackTitle && (
            <Typography variant="h3" sx={{ mb: { xs: 6, md: 10 } }}>
              {techStackTitle}
            </Typography>
          )}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {techStack &&
              techStack
                .split('\n')
                .filter((entry) => entry)
                .map((entry, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'auto',
                      height: { xs: 40, md: 48 },
                      m: 2,
                      ml: 0,
                      px: { xs: 5, md: 6 },
                      borderRadius: 24,
                      color: 'white',
                      backgroundColor: theme.palette.grey[500],
                    }}
                  >
                    <Typography variant="chip2">{entry}</Typography>
                  </Box>
                ))}
          </Box>
        </Section>
      )}
    </React.Fragment>
  )
}
