import React, { ReactElement, useEffect, useState, useContext } from 'react'

import { IconButton, useTheme } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'

import ScrollIndicatorCircle from '@static/img/svgIcons/scrollIndicator/Circle.svg'
import ScrollIndicatorArrow from '@static/img/svgIcons/scrollIndicator/Arrow.svg'

import ThemeContext from '@providers/themeProvider'

export type ScrollIndicatorProps = LAW.Props.IDefault &
  LAW.Contentful.INodeDefaults & {
    pageThemeOverride?: boolean
  }

export default function ScrollIndicator({
  pageThemeOverride,
}: ScrollIndicatorProps): ReactElement {
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => setPageHeight(window.innerHeight), [])
  const theme = useTheme()
  const { theme: pageTheme } = useContext(ThemeContext)

  function scrollToViewport() {
    window.scrollBy({
      top: pageHeight,
      behavior: 'smooth',
    })
  }

  return (
    <IconButton
      sx={{
        color:
          pageThemeOverride === true
            ? theme.palette.primary.main
            : pageTheme === 'sustainability'
            ? theme.palette.sustainability.main
            : theme.palette.primary.main,
        padding: 0,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      }}
      onClick={() => scrollToViewport()}
    >
      <SvgIcon
        component={ScrollIndicatorCircle}
        viewBox={'0 0 68 82'}
        sx={{
          position: 'relative',
          width: '3.4em',
          height: '3.4em',
        }}
      />
      <SvgIcon
        component={ScrollIndicatorArrow}
        viewBox={'0 0 68 82'}
        sx={{
          position: 'absolute',
          left: '19px',
          top: '-15px',
          width: '3.4em',
          height: '3.4em',
          transform: 'translatey(0px)',
          animation: 'float 1.5s ease-in-out infinite',
          '@keyframes float': {
            '0%': {
              transform: 'translatey(0px)',
            },
            '50%': {
              transform: 'translatey(-10px)',
            },
            '100%': {
              transform: 'translatey(0px)',
            },
          },
        }}
      />
    </IconButton>
  )
}
