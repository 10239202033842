import React, { ReactElement, useContext } from 'react'

import { Typography } from '@mui/material'
import { Box, Theme } from '@mui/material'

import ThemeContext from '@providers/themeProvider'

export type QuoteProps = LAW.Props.IDefault & {
  author?: string
  position?: string
  html?: string
  offset?: boolean
  size?: 'small' | 'large'
  type?: string
}

export default function Quote({
  author,
  position,
  children,
  html,
  offset,
  sx = [],
}: QuoteProps): ReactElement {
  const { theme: pageTheme } = useContext(ThemeContext)

  const additionalProps = {
    ...(html && {
      dangerouslySetInnerHTML: {
        __html: html,
      },
    }),
  }
  const withCaptions = author || position

  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'flex-start',
          transform: { lg: offset ? 'translateX(-200px)' : 'none' },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box component="span">
        <Typography
          variant="quote"
          component={'span'}
          {...additionalProps}
          sx={(muiTheme: Theme) => ({
            display: 'inline-block',
            color:
              pageTheme === 'sustainability'
                ? muiTheme.palette.sustainability.main
                : muiTheme.palette.primary.main,
            textAlign: 'center',
            mb: withCaptions ? 6 : undefined,
            '& > *': {
              display: 'inline',
            },
            '& u': {
              textDecorationColor: '#e50040',
            },
          })}
          gutterBottom={false}
        >
          {children as React.ReactNode}
        </Typography>
        {author && (
          <Typography
            variant="caption"
            sx={(muiTheme: Theme) => ({
              display: 'block',
              color:
                pageTheme === 'sustainability'
                  ? muiTheme.palette.sustainability.main
                  : muiTheme.palette.primary.main,
              textAlign: 'center',
              [muiTheme.breakpoints.up('md')]: {
                mt: 6,
              },
            })}
          >
            — {author}
          </Typography>
        )}
        {position && (
          <Typography
            variant="caption"
            sx={(muiTheme: Theme) => ({
              display: 'block',
              textTransform: 'none',
              color:
                pageTheme === 'sustainability'
                  ? muiTheme.palette.sustainability.main
                  : muiTheme.palette.primary.main,
              textAlign: 'center',
            })}
          >
            {position}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
