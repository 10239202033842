import React, { ReactElement } from 'react'
import { Box, Typography, Theme, styled } from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import Image from '@components/core/media/image'
import Video from '@components/core/media/video'

export type TeaserCarouselItemProps = LAW.Props.IDefault & {
  caption?: string
  jobTitle?: string
  media?: LAW.Props.Video | LAW.Props.Image
  poster?: LAW.Props.Image
  linkTo?: LAW.Props.LinkTo
  itemIndex: string
  withVideo: boolean
}

export default function TeaserCarouselItem({
  media,
  poster,
  caption,
  jobTitle,
  withVideo,
  linkTo,
  itemIndex,
}: TeaserCarouselItemProps): ReactElement {
  const SxLink =
    !withVideo && linkTo ? styled(GatsbyLink)({}) : styled('div')({})

  return (
    <SxLink
      to={linkTo?.url as string}
      sx={(theme) => ({
        textDecoration: 'none',
        '& img': {
          height: '100%',
          width: '100%',
          top: '0%',
          left: '0%',
          transition: 'all .3s !important',
        },
        [theme.breakpoints.up('lg')]: {
          '&:hover': {
            '& img': {
              height: '120% !important',
              width: '120% !important',
              top: '-10% !important',
              left: '-10% !important',
              transition: 'all .3s',
            },
          },
        },
      })}
    >
      <Box>
        {!!withVideo && !!media && !!poster && (
          <Video
            videoItemIndex={itemIndex}
            src={media.file.url}
            poster={poster.file.url}
            sx={{
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          />
        )}
        {!withVideo && !!media && (
          <Image
            data={(media as LAW.Props.Image).data}
            sx={{
              borderRadius: '8px',
              overflow: 'hidden',
              '& img': {
                borderRadius: '8px',
              },
            }}
          />
        )}

        <Box
          sx={(muiTheme: Theme) => ({
            backgroundColor: withVideo ? muiTheme.palette.grey[100] : null,
            mt: withVideo ? 2 : { xs: 4, lg: 6 },
            padding: withVideo ? 6 : null,
            borderRadius: '8px',
          })}
        >
          {!!caption && (
            <Typography component="h3" variant={withVideo ? 'h4' : 'h3'}>
              {caption}
            </Typography>
          )}
          {!!jobTitle && <Typography variant="body2">{jobTitle}</Typography>}
        </Box>
      </Box>
    </SxLink>
  )
}
